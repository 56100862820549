import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { dashboardService } from '../../service/dashboardService';
import { toast } from 'react-toastify';
import ProgressBar from '../../components/progressBar/ProgressBar';
import { Lock } from '@mui/icons-material';

const StudyPlan = () => {
    const [modules, setModules] = useState([]);
    const [expandedChapter, setExpandedChapter] = useState(null);


    const fetchModules = async () => {
        try {
            const response = await dashboardService().getLectures();
            if (response?.status === 200) {
                setModules(response?.data?.results);
            } else {
                toast.error(response?.message);
            }
            console.log(response, 'response');
        } catch (error) {
            toast.error(error?.message);

            console.error(error, 'error');
        }
    };

    useEffect(() => {
        fetchModules();
    }, []);

    const toggleChapter = (id) => {
        setExpandedChapter((prev) => (prev === id ? null : id));
    };

    return (
        <div className="min-h-screen bg-gray-50 p-8">
            <h2 className="text-3xl font-bold p-4 text-gray-800 mb-8">Study Plan</h2>
            <div className="max-w-6xl space-y-6">
                {modules.sort((a, b) => a.id - b.id).map((module) => {
                    return (<div
                        onClick={() => toggleChapter(module.id)}
                        key={module.id}
                        className="bg-white shadow-lg rounded-lg p-4 transition duration-300 cursor-pointer"
                    >
                        {/* Chapter Header */}
                        <div
                            className="flex justify-between items-center "

                        >
                            <h3 className="text-xl font-semibold text-gray-800">{module.title}</h3>
                            <span
                                className={`transform transition-transform duration-300 ${expandedChapter === module.id ? 'rotate-180' : 'rotate-0'
                                    }`}
                            >
                                ▼
                            </span>
                        </div>

                        {/* Expandable Content */}
                        <div
                            className={`overflow-hidden transition-all duration-500 ease-in-out ${expandedChapter === module.id ? 'max-h-screen' : 'max-h-0'
                                }`}
                        >
                            <div className="mt-4 space-y-4">
                                {/* Lecture Video */}
                                <Link
                                
                                    to={`/lecture/${module.id}`}
                                    state={{ title: module.title, url: module.video_url }}
                                    className="block bg-blue-100 hover:bg-blue-200 text-blue-800 font-medium py-3 px-4 rounded transition duration-200 ease-in-out"
                                >
                                    {`Lecture Video`}
                                </Link>

                                {/* MCQ Section */}
                                <Link
                                    to={module?.mcq_eligible ? "/attend-test":"#"}
                                    state={{
                                        lectureId: module.id,
                                        testType: 'mcq',
                                        gradeId: module?.grade?.id,
                                        lecture: module?.title,
                                    }}
                                    
                                    className="flex relative items-center justify-between bg-gray-100 hover:bg-gray-200 p-4 rounded transition duration-200 ease-in-out"
                                >
                                    <p className="text-gray-800 font-medium">MCQs ({module?.total_mcq})</p>
                                    <div className="w-1/2">
                                        <ProgressBar progress={Math.round(module?.mcq_progress)} showPercent />
                                    </div>
                                    {!module?.mcq_eligible && <div className='absolute top-3 right-2'> <Lock style={{fontSize:16}} /></div>}
                                </Link>

                                {/* Simulation Tests */}
                                <Link
                                    to={module?.sim_eligible ? "/attend-test":"#"}
                                    state={{
                                        lectureId: module.id,
                                        testType: 'simulation',
                                        gradeId: module?.grade?.id,
                                        lecture: module?.title,
                                    }}
                                    className="flex relative items-center justify-between bg-gray-100 hover:bg-gray-200 p-4 rounded transition duration-200 ease-in-out"
                                >
                                    <p className="text-gray-800 font-medium">
                                        Simulation Tests ({module?.total_simulation})
                                    </p>
                                    <div className="w-1/2">
                                        <ProgressBar progress={Math.round(module?.simulation_progress)} showPercent />
                                    </div>
                                    {!module?.sim_eligible && <div className='absolute top-3 right-2'> <Lock style={{fontSize:16}} /></div>}
                                </Link>

                                {/* Long Answer Tests */}
                                <Link
                                    to={module?.la_eligible ? "/attend-test":"#"}
                                    state={{
                                        lectureId: module.id,
                                        testType: 'long_answer',
                                        gradeId: module?.grade?.id,
                                        lecture: module?.title,
                                    }}
                                    className="block relative bg-gray-100 hover:bg-gray-200 text-gray-800 font-medium p-4 rounded transition duration-200 ease-in-out"
                                >
                                    Long Answers ({module?.total_long_answer})
                                    {!module?.la_eligible && <div className='absolute top-3 right-2'> <Lock style={{fontSize:16}} /></div>}

                                </Link>
                            </div>
                        </div>
                    </div>

                    )
                })}
            </div>
        </div>
    );
};

export default StudyPlan;
