import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ routes }) => {
    return (
        <nav className="text-sm text-gray-600 mb-4">
            <ol className="flex items-center space-x-2">
                {routes.map((route, index) => {
                    const isLast = index === routes.length - 1;

                    return (
                        <li key={route.path} className="flex items-center space-x-2">
                            {index > 0 && <span>/</span>}
                            {isLast ? (
                                <span className="text-gray-500">{route.label}</span>
                            ) : (
                                <Link to={route.path} className="text-blue-500 hover:underline">
                                    {route.label}
                                </Link>
                            )}
                        </li>
                    );
                })}
            </ol>
        </nav>
    );
};

export default Breadcrumb;
