import React, { useEffect, useState } from 'react'
import { dashboardService } from '../../service/dashboardService'
import { useNavigate, useParams } from 'react-router-dom'
import BackNavSection from '../../components/backNav/BackNavSection'
import { toast } from 'react-toastify'
import YouTube from 'react-youtube'
import { getYouTubeVideoId } from '../../utils/helpers'

const ModuleDetailView = () => {
    const [data,setData] = useState(null)

    const {id} =  useParams()
    const navigate =useNavigate()

    const fetchData = async () => {
        try {
            const response = await dashboardService().getLectureDetails(id)
            console.log(response,'res')
            if(response?.status === 200){
                setData(response?.data)

            }else{
                toast.error(response?.message)
            }
        } catch (error) {
            console.log(error.message,'error')
            toast.error(error?.message)
        }
    }

    const navigateBack = ()=>{
        navigate('/modules')
    }

    const opts = {
        height: '500',
        width: '900',
        playerVars: {
            autoplay: 1,
        },
    };


    useEffect(()=>{
        fetchData()
    },[])
    return (
        <div>
           <BackNavSection navigateBack={navigateBack} />
            <h2>{data?.title}</h2>
            <div className="rounded flex itemc-center justify-center mt-12">
                <YouTube videoId={getYouTubeVideoId(data?.video_url)} opts={opts} />
            </div>
        </div>
    )
}

export default ModuleDetailView