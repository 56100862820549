import React from 'react'

const Logo = ({ onClick }) => {
    return (
        <div onClick={onClick} className='cursor-pointer w-[120px]  text-center '>
            <div className="flex items-center justify-center gap-x-2">
                <p className="text-[32px] font-bold text-[#000] ">T</p>
                <p className="text-[32px] font-bold text-[#000080]">C</p>
                <p className="text-[32px] font-bold text-gold">A</p>
            </div>
            <div className="flex items-center justify-center gap-[3px]">
                <p className="text-[8px] font-bold text-[#000] ">The</p>
                <p className="text-[8px] font-bold text-navyBlueLight">Class</p>
                <p className="text-[8px] font-bold text-[#000]">of</p>
                <p className="text-[8px] font-bold text-gold">Accounting</p>
            </div>
        </div>
    )
}

export default Logo