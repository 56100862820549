import { Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { questionBankService } from '../../service/questionBankService';
import { toast } from 'react-toastify';


const LongAnswerPreview = ({ isOpen, handleClose, id }) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await questionBankService().getLongAnsweDetails(id)
      setLoading(false)
      console.log(response, 'res')
      if (response?.status === 200) {
        setData(response?.data)
      }else{
        toast.error(response.message)
      }
      console.log('res', response)
    } catch (error) {
      console.log('error', error.message)
      toast.error(error.message)

    }
  }

  useEffect(() => {
    if(id) fetchData()
  }, [id])
// return <div></div>
  
  return (
    <Modal open={isOpen} onClose={handleClose}>
     {loading ? <div>Loading....</div>: <div className="p-6 rounded-lg shadow-md max-w-xl mx-auto mt-[20%] bg-white">
        <h2 className="text-xl font-semibold mb-4">{data?.question}</h2>
        <div className="grid grid-cols-2 gap-4">
        {data?.answer}
        </div>
      </div>}
    
    </Modal>
  );
};

export default LongAnswerPreview;
