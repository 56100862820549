// src/redux/authSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const exerciseTestSlice = createSlice({
  name: 'auth',
  initialState: {
    isShowAnswer: false,
    dummySimulation: {
      cellData:[],
      question:'',
    },
   
  },
  reducers: {
    showAnswer: (state,action) => {
      state.showAnswer = action.payload;
    },
    createDummySimulation: (state,action) => {
      state.dummySimulation = action.payload;
    },
  
  },
});

export const { showAnswer,createDummySimulation } = exerciseTestSlice.actions;

export default exerciseTestSlice.reducer;
