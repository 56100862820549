import React, { useEffect, useState } from 'react'
import PrimaryButton from '../../../components/button/PrimaryButton'
import { ChevronLeft, ChevronRight, Flag } from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'
import { practiceTestService } from '../../../service/practiceTestService'
import PracticetestResultModal from './PracticetestResultModal'
import PracticetestSolutionModal from './PracticetestSolutionModal'
import McqPracticeTestView from './McqPracticeTestView'
import Timer from '../../../components/timer/Timer'
import SimulationPracticeTestView from './SimulationPracticeTestView'
import { useCallbackPrompt } from '../../../hooks/useCallbackPrompt'
import DialogBox from '../../../components/dialogBox/DialogBox'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

function PracticetestLayout() {
    const location = useLocation();
    const navigate = useNavigate()
    const [questions, setQuestions] = useState([])
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
    const [currentSolutionIndex, setCurrentSolutionIndex] = useState(0)
    const [selectedOption, setSelectedOption] = useState(null)
    const [istimerRunning, setistimerRunning] = useState(false)
    const [correctAnswer, setCorrectAnswer] = useState(null)

    const { question_type, lecture_ids, questions_count } = useSelector((state) => state.practice)
    const testType = question_type
    // const [showPrompt, confirmNavigation, cancelNavigation] =
    //     useCallbackPrompt(true)


    const [showResultModal, setShowResultModal] = useState(false)
    const [showSolutionsModal, setShowSolutionsModal] = useState(false)

    const handleResultModalClose = () => {
        setShowResultModal(false)
        setShowSolutionsModal(false)
        navigate('/practice-test')
    }

    const stopTestAndExit = async () => {
        setistimerRunning(false); // Stop timer
        try {
            const response = await practiceTestService().getPracticeTestSummary();
            console.log('Test summary fetched:', response);
        } catch (error) {
            console.error('Error fetching test summary:', error);
            toast.error(error.message)
        }
    };

    useEffect(() => {
        // Return a cleanup function to run only on unmount
        return () => {
            stopTestAndExit(); // This will run when the component unmounts
        };
    }, []);




    const startTest = async () => {
        let data = {
            lecture_ids: lecture_ids,
            question_type: question_type,
            questions_count: questions_count
        }
        try {
            const response = await practiceTestService().startPracticeTest(data)
            console.log(response, 'res ponse')
            if (response?.status === 200) {
                fetchQuestions()
            } else {
                console.log('error start')
                toast.error(response.message)
            }
        } catch (error) {
            toast.error(error.message)
        }


    }

    const handleShowSolution = () => {
        setShowResultModal(false)
        setShowSolutionsModal(true)
    }

    const fetchQuestions = async () => {
        const response = await practiceTestService().getPracticeTestQuestions()
        console.log(response, 'response list')

        try {
            if (response?.status === 200) {
                setQuestions(response?.data?.question_ids)
                setistimerRunning(true)
            } else {
                console.log('error start')
                toast.error(response.message)
            }
        } catch (error) {
            toast.error(error.message)
        }
    }

    useEffect(() => {
        if (question_type) startTest()
    }, [question_type])


    return (
        <div className=' relative'>
            <div className="flex w-full justify-between items-center  border-b-2 p-2">
                {/* <p className="text-xl font-bold">{lecture} - {testType} </p> */}
                <Timer start={istimerRunning} stop={!istimerRunning} />
                <PrimaryButton onClick={() => {
                    setistimerRunning(false)
                    setShowResultModal(true)
                }} type="button" className='text-white bg-highlight' >
                    Exit
                </PrimaryButton>
            </div>
            <div className='flex items-center justify-start p-4 gap-52'>
                <p className={`text-xl  text-amber-500 font-bold `}>{currentQuestionIndex + 1}/{questions?.length} </p>
            </div>
            {question_type === 'SIMULATION' && <SimulationPracticeTestView setCorrectAnswer={setCorrectAnswer} answersObject={correctAnswer} setAnswersForSubmitting={setSelectedOption} answersForSubmitting={selectedOption} testType={testType} id={questions[currentQuestionIndex]} />}
            {question_type === 'MCQ' && <McqPracticeTestView testType={testType} setSelectedOption={setSelectedOption} selectedOption={selectedOption} id={questions[currentQuestionIndex]} />}
            {/* {testType === 'long_answer' && <LongAnswerExerciseView testType={testType} setCorrectAnswer={setCorrectAnswer} correctAnswer={correctAnswer} setSelectedOption={setSelectedOption} selectedOption={selectedOption} id={questions[currentQuestionIndex]?.id} shwCorrectAnswerNow={shwCorrectAnswerNow} setShwCorrectAnswerNow={setShwCorrectAnswerNow} />} */}
            <div className='flex items-center justify-center gap-2'>
                <div onClick={() => {
                    if (currentQuestionIndex != 0) {
                        setCorrectAnswer(null)
                        setSelectedOption(null)
                        setCurrentQuestionIndex(prev => prev - 1)
                    }
                }} className='cursor-pointer border p-4 rounded bg-gray-100'>
                    <ChevronLeft />
                </div>
                {currentQuestionIndex + 1 != questions.length ? <div
                    onClick={() => {
                        if (currentQuestionIndex + 1 != questions.length) {
                            setCorrectAnswer(null)
                            setSelectedOption(null)
                            setCurrentQuestionIndex(prev => prev + 1)
                        }
                    }}
                    className='cursor-pointer  border p-4 rounded'>
                    <ChevronRight />
                </div> : <div onClick={() => {
                    setistimerRunning(false)
                    setShowResultModal(true)
                }} className='cursor-pointer border p-4 rounded bg-gray-100'>Submit</div>}

            </div>
            <PracticetestResultModal isOpen={showResultModal} handleClose={handleResultModalClose} handleShowSolution={handleShowSolution} />
            <PracticetestSolutionModal questionType={question_type} currentQuestionIndex={currentSolutionIndex} onPrevious={() => {
                if (currentSolutionIndex != 0) {
                    setCurrentSolutionIndex(prev => prev - 1)
                }
            }
            }
                onNext={() => {
                    if (currentSolutionIndex + 1 != questions.length) {
                        setCurrentSolutionIndex(prev => prev + 1)
                    }
                }
                }
                currentQuestionId={questions[currentSolutionIndex]} isOpen={showSolutionsModal} handleClose={() => {
                    setShowSolutionsModal(false)
                    setShowResultModal(false)
                    navigate('/practice-test')

                }} totalQues={questions?.length} />
        </div>
    )
}

export default PracticetestLayout