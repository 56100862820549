import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { dashboardService } from '../../../service/dashboardService';
import { useParams } from 'react-router-dom';
import { Modal } from '@mui/material';

// Validation schema
const schema = yup.object({
    start_date: yup
        .date()
        .required('Start date is required')
        .typeError('Please provide a valid date'),
    end_date: yup
        .date()
        .required('End date is required')
        .min(yup.ref('start_date'), 'End date cannot be before start date')
        .typeError('Please provide a valid date'),
    plan: yup.number().required('Please select a plan'),
});
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const SubscriptionFormModal = ({ isOpen, onClose, studentId, onSuccess }) => {

    const [plansList, setPlansList] = useState([])

    const [isLoading, setIsLoading] = useState(false)

    const {
        register,
        handleSubmit,
        watch,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            start_date: new Date().toISOString().split('T')[0], // Today's date
            end_date: new Date(new Date().setMonth(new Date().getMonth() + 1))
                .toISOString()
                .split('T')[0], // One month from today
            plan: ''
        },
    });


    const fetchPlans = async () => {
        try {
            const response = await dashboardService().getSubscriptionPlans()
            setIsLoading(false)
            if (response.status === 200) {
                setPlansList(response.data.results)
            }
            console.log(response.data, 'response plans')
        } catch (error) {
            toast.error('Failed to retireve list')
        }
    }

    const addSubscription = async (data) => {

        const formData = { ...data, student: studentId }
        try {
            const response = await dashboardService().addSubscription(formData)
            setIsLoading(false)
            if (response.status === 201) {
                onSuccess()
            } else {
                onClose()
                toast.error(response?.message)
            }
        } catch (error) {
            onClose()
            toast.error(error?.message)

        }
    }


    useEffect(() => {
        fetchPlans()
    }, [])

    const onSubmit = (data) => {
        setIsLoading(true)
        addSubscription(data)
    };

    const watchPlan = watch("plan")
    console.log(watchPlan, 'watchPlan')

    return (
        <Modal open={isOpen} onClose={onClose}>
            <div style={style} className="modal-content w-full relative !pt-[40px]">


                {/* Form */}
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 ">
                    <div className="flex gap-4 my-12 w-full ">
                        {/* Start Date Input */}
                        <div className="w-1/3">
                            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="start_date">
                                Start Date
                            </label>
                            <input
                                type="date"
                                id="start_date"
                                min={new Date().toISOString().split('T')[0]}
                                {...register('start_date')}
                                className={`block w-full px-3 py-2 border rounded-lg focus:ring focus:ring-indigo-200 focus:border-indigo-500 sm:text-sm ${errors.start_date ? 'border-red-500' : 'border-gray-300'
                                    }`}
                            />
                            {errors.start_date && (
                                <p className="mt-1 text-sm text-red-500">{errors.start_date.message}</p>
                            )}
                        </div>

                        {/* End Date Input */}
                        <div className="w-1/3">
                            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="end_date">
                                End Date
                            </label>
                            <input
                                type="date"
                                id="end_date"
                                min={new Date().toISOString().split('T')[0]}
                                {...register('end_date')}
                                className={`block w-full px-3 py-2 border rounded-lg focus:ring focus:ring-indigo-200 focus:border-indigo-500 sm:text-sm ${errors.end_date ? 'border-red-500' : 'border-gray-300'
                                    }`}
                            />
                            {errors.end_date && (
                                <p className="mt-1 text-sm text-red-500">{errors.end_date.message}</p>
                            )}
                        </div>
                        <div className='w-1/3'>
                            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="plan">
                                Plan
                            </label>
                            <Controller
                                name="plan"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <select {...field} className="block w-full px-3 py-2 border rounded-lg focus:ring focus:ring-indigo-200 focus:border-indigo-500 sm:text-sm ">
                                        {plansList.map(i => <option key={i.id} value={i.id}>{i.description}</option>)}
                                    </select>
                                )}
                            />
                            {/* <select
                                id="plan"
                                onChange={(e)=>setValue('plan',e.target.value)}
                                value={watchPlan}
                                aria-placeholder='Select Plan'
                                className={`block w-full px-3 py-2 border rounded-lg focus:ring focus:ring-indigo-200 focus:border-indigo-500 sm:text-sm ${errors.plan ? 'border-red-500' : 'border-gray-300'
                                    }`}
                            >
                                {plansList.map(i => <option key={i.id} value={i.id}>{i.description}</option>)}
                            </select> */}
                            {errors.plan && (
                                <p className="mt-1 text-sm text-red-500">{errors.plan.message}</p>
                            )}
                        </div>
                    </div>

                    <button
                        type="submit"
                        className=" mx-auto w-full py-2 px-4 text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:ring focus:ring-indigo-200"
                    >
                        Add Subscription
                    </button>
                </form>

            </div>
        </Modal>
    );
};

export default SubscriptionFormModal;
