import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { questionBankService } from '../../../service/questionBankService'
import { testService } from '../../../service/testService'
import Loader from '../../../components/loader/Loader'
import { Flag } from '@mui/icons-material'

const optionLabels = ['A', 'B', 'C', 'D']

const McqExerciseView = ({ id, setCorrectAnswer, correctAnswer, setSelectedOption, selectedOption, testType }) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [checkingAnswer, setCheckingAnswer] = useState(false)

  const showAnswer = useSelector((state) => state.exercise.showAnswer);

  console.log(data, 'data')

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await questionBankService().getMcqDetails(id)
      setLoading(false)
      console.log(response, 'res')
      if (response?.status === 200) {
        setData(response?.data)
      }
      console.log('res', response)
    } catch (error) {
      console.log('error', error.message)
    }
  }

  // const submitAnswer = async () => {
  //   setLoading(true)
  //   try {
  //     const response = await questionBankService().getMcqDetails(id)
  //     setLoading(false)
  //     console.log(response, 'res')
  //     if (response?.status === 200) {
  //       setData(response?.data)
  //     }
  //     console.log('res', response)
  //   } catch (error) {
  //     console.log('error', error.message)
  //   }
  // }


  const checkAnswer = async () => {
    console.log(selectedOption, 'selected')
    setCheckingAnswer(true)
    let submitData = {
      answer: selectedOption
    }
    try {
      const response = await testService().checkExerciseMcqAnswer(id, submitData)
      console.log(response, 'res')
      if (response?.status === 200) {
        setCorrectAnswer(response?.data?.answer)
        submitAnswer()
      } else {
        console.log('something went wrong : check answer')
      }
      console.log('res', response)
    } catch (error) {
      console.log('error', error.message)
    }
  }


  const submitAnswer = async () => {
    setCheckingAnswer(true)

    let submitData = {
      answer: selectedOption
    }
    try {
      const response = await testService().submitAnswer(id, submitData, testType)
      setCheckingAnswer(false)
      console.log(response, 'res')
      // if (response?.status === 200) {

      // }
      console.log('res submi  answer', response)
    } catch (error) {
      console.log('error submit answer', error.message)
    }
  }

  useEffect(() => {
    if (id) fetchData()
  }, [id])

  useEffect(() => {
    if (selectedOption) checkAnswer()
  }, [selectedOption])

  const getColorForOption = (option) => {
    console.log(option, 'option')
    console.log(correctAnswer, 'correct')

    if (selectedOption === option) {
      if (correctAnswer && option != correctAnswer) {
        return { backgroundColor: 'red' }
      } else if (option == correctAnswer) {
        return { backgroundColor: 'green' }
      } else {
        return { backgroundColor: 'orange' }
      }
    } else {
      if (correctAnswer === option) {
        return { backgroundColor: 'green' }

      }
      return { backgroundColor: 'white' }
    }
  }

  useEffect(() => {
    if (data?.last_answered) {
      setSelectedOption(data?.last_answered)
      checkAnswer()
    }
  }, [data])

  return (
    <div className="flex bg-gray-200">
      <div className="w-2/3 bg-white border rounded p-2 h-[65vh] overflow-auto relative" >
        <div onClick={() => { }} className='absolute cursor-pointer  border border-gray-400 rounded-full  grid place-content-center w-8 h-8 top-0 right-4 hover:bg-gray-200'>
          <Flag style={{ color: 'green', fontSize: '10px' }} />
        </div>
        <div className='h-[85%] relative'>
          {checkingAnswer && <div className='absolute top-0 left-0 bg-white opacity-[0.8] h-full w-full grid place-content-center'><Loader /> </div>}
          {loading ? <div className='h-full w-full grid place-content-center'>Loading....</div> : <div className="p-6  bg-white">
            <h2 className="text-xl font-semibold mb-8">{data?.question}</h2>
            {data && data.id ? <div className="grid grid-cols-2 gap-4">
              {optionLabels.map(option => (
                <div key={option} onClick={() => setSelectedOption(option)} style={getColorForOption(option)} className={`p-4 rounded-lg shadow-md  cursor-pointer`}>
                  <label className="flex items-center space-x-3">
                    <span className="text-gray-700">{data[`option_${option.toLowerCase()}`]}</span>
                  </label>
                </div>
              ))}
            </div> : null}
            {correctAnswer ? <div className='mt-32'>
              <h3 className='font-bold'>Explanation</h3>
              <p className="text-sm">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate unde ipsa repudiandae, velit asperiores et eveniet facilis voluptatum illum quasi earum totam nam ducimus harum pariatur esse blanditiis, maiores modi?
              </p>
            </div> : null}

          </div>}
        </div>
      
      </div>
      
    </div>
  )
}

export default McqExerciseView