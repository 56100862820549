import React, { useEffect, useState } from 'react'
import { dashboardService } from '../../service/dashboardService'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Check, Close } from '@mui/icons-material'

const Plans = () => {
    const [list, setList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState("");

    const openPopup = (planName) => {
        setSelectedPlan(planName);
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
        setSelectedPlan("");
    };

    const sendWhatsApp = () => {
        const message = `Hi, I am interested in the \"${selectedPlan}\" plan and would like to try it. Could you please provide more details?`;
        const encodedMessage = encodeURIComponent(message);
        const whatsappUrl = `https://wa.me/966545174414?text=${encodedMessage}`;
        window.open(whatsappUrl, "_blank");
    };

    const sendEmail = () => {
        const subject = `Interested in the ${selectedPlan} Plan`;
        const body = `Hi,\n\nI am interested in the \"${selectedPlan}\" plan and would like to try it. Could you please provide more details?`;
        const mailtoUrl = `mailto:naseempathuthara@gmail.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.open(mailtoUrl, "_blank");
    };


    const fetchList = async () => {
        setIsLoading(true)
        try {
            const response = await dashboardService().getSubscriptionPlans()
            setIsLoading(false)
            setList([1, 2, 3])
            if (response.status === 200) {
                setList(response.data.results)
            }
            console.log(response.data, 'response')
        } catch (error) {
            toast.error('Failed to retireve list')
        }
    }

    const planDescriptions = [
        { plan_name: 'free', }
    ]

    const premiumDescriptions = [
        { title: 'Complete Chapter Access:', description: 'Gain full access to MCQs and numerical practice for all chapters—ensuring you’re 100% prepared for exams and real-world application.' },
        { title: 'Build a Strong Foundation for Success: ', description: 'Access life skills videos to sharpen your mindset, boost confidence, and develop habits that fuel personal growth and achievement.' },
        { title: 'Skill-Building Tools:', description: 'Gain essential skills like Excel, Tally, and Power BI, making you more valuable.' },
        { title: 'Personalized Guidance:', description: 'With one-on-one sessions from counselors, you’ll receive tailored advice to overcome obstacles and speed up your progress.' },
        { title: 'Expert Insights:', description: 'Get exclusive mentor talks that give you career advice, industry insights, and strategies for personal growth.' },
        { title: 'Collaborative Study:', description: 'Join group study sessions to stay motivated, share ideas, and succeed together.' }

    ]

    const rewardsDescriptions = [
        { title: 'Accountability Partner:', description: 'Stay on track with a dedicated accountability guide who will check in with you regularly, ensuring consistent progress and helping you stay motivated toward your goals.' },
        { title: 'Scuba Diving Experience:', description: 'Break free from the ordinary! Enjoy an unforgettable scuba diving experience, offering a unique adventure to refresh your mind and add a thrilling memory to your journey.' },
        { title: '₹20,000 Cashback on Bike Purchase', description: "When it's time to step into college life, get upto ₹20,000 cashback on purchasing a bike—making your transition smoother, stylish, and affordable." },
      
    ]
    const navigate = useNavigate()

    useEffect(() => {
        fetchList()
    }, [])
    return (
        <div className="bg-gray-100 py-12">
            <div className=" mx-auto px-6 lg:px-8">
                <h2 className="text-4xl font-bold text-center text-gray-800 mb-10">
                    Choose Your Plan
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {/* Free Plan */}
                    <div className="bg-white border border-gray-300 rounded-xl shadow-lg p-6 flex flex-col">
                        <div className='relative'>
                            <h3 className="text-2xl font-semibold text-gray-700">FREE</h3>
                        </div>
                        <p className="font-bold mt-4 text-[14px] mb-4">Start Learning wth ease :</p>
                        <ul className="mb-4 space-y-2 text-[14px] ">
                            <li className='mb-2'><Check style={{ color: 'green' }} /> Access high-quality video lessons for all chapters.</li>
                            <li className='mb-2'><Check style={{ color: 'green' }} /> Try MCQs for 1 chapter with our advanced system.</li>
                            <li className='mb-2'><Check style={{ color: 'green' }} /> Use numerical practice for 1 chapter.</li>
                        </ul>
                        <button disabled onClick={() => openPopup("FREE")} className="mt-auto bg-secondary text-gray-800 py-2 px-4 rounded-lg ">
                            Current Plan
                        </button>
                        <div className="mt-6 text-sm text-gray-600 h-1/2 ">
                            <p className="font-bold mb-4">What you will miss out on:</p>
                            <ul className=" ml-6">
                                <li className='mb-2'><Close style={{ color: 'tomato' }} /> Full access to all chapter MCQs and numerical problems.</li>
                                <li className='mb-2'><Close style={{ color: 'tomato' }} /> Life improvement videos for building success-driven habits.</li>
                                <li className='mb-2'><Close style={{ color: 'tomato' }} /> Training in Excel, Tally, and Power BI.</li>
                                <li className='mb-2'><Close style={{ color: 'tomato' }} /> One-on-one counselor sessions and mentor talks.</li>
                                <li className='mb-2'><Close style={{ color: 'tomato' }} /> Group study sessions and rewards worth ₹20,000.</li>
                            </ul>
                        </div>

                    </div>

                    {/* Premium Plan */}
                    <div className="bg-white border border-gray-300 rounded-lg shadow-lg p-6 flex flex-col hover:scale-105 duration-300 transform transition">
                        <h3 className="text-2xl font-semibold text-gray-700">PREMIUM</h3>
                        <p className="mt-2 text-[40px] font-bold font-sans text-gray-800 text-center ">SR 260 /mo</p>

                        <div className="my-2 space-y-2">
                            <div className='font-bold'> Everything in the Free Plan plus:</div>
                            {premiumDescriptions.map((i, index) => <div key={index} className="flex gap-2 items-start">
                                <Check style={{ color: 'green', fontSize: '22px' }} />
                                <p className="text-gray-800 text-[14px]">
                                    <span className="font-bold">{i.title}</span>{' '}<span className="font-light">{i.description}</span>
                                </p>
                                {/* <p className='text-[14px]'> <p className='font-bold'>{i.title}</p><p>{i.description}</p></p> */}
                            </div>)}
                        </div>
                        <button onClick={() => openPopup("PREMIUM")} className="mt-auto  text-white py-2 px-4 mt-2 rounded-lg bg-highlight hover:bg-gold hover:font-bold">
                            Yes, give me my premium access!
                        </button>

                    </div>

                    {/* Rewards Plan */}
                    <div className="bg-white border border-gray-300 rounded-lg shadow-lg p-6 flex flex-col hover:scale-105 duration-300 transform transition">
                        <h3 className="text-2xl font-semibold text-gray-700">REWARDS</h3>
                        <p className="mt-2 text-[40px] font-bold font-sans text-gray-800 text-center ">SR 520 /mo</p>

                        <div className="my-2 space-y-2">
                            <div className='font-bold'> Everything in the Premium Plan plus:</div>
                            {rewardsDescriptions.map((i, index) => <div key={index} className="flex gap-2 items-start">
                                <Check style={{ color: 'green', fontSize: '22px' }} />
                                <p className="text-gray-800 text-[14px]">
                                    <span className="font-bold">{i.title}</span>{' '}<span className="font-light">{i.description}</span>
                                </p>
                                {/* <p className='text-[14px]'> <p className='font-bold'>{i.title}</p><p>{i.description}</p></p> */}
                            </div>)}
                        </div>
                        <button onClick={() => openPopup("REWARDS")} className="mt-auto  text-white py-2 px-4 mt-2 rounded-lg bg-highlight hover:bg-gold hover:font-bold">
                        Yes, let me invest in myself!
                        </button>
                    </div>
                </div>
            </div>
            {isPopupOpen && (
                <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg shadow-lg p-6 w-96">
                        <h3 className="text-xl font-semibold text-gray-800 mb-4">
                            Contact Us about the {selectedPlan} Plan
                        </h3>
                        <p className="text-gray-600 mb-6">
                            How would you like to reach us?
                        </p>
                        <div className="flex space-x-4">
                            <button
                                className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600"
                                onClick={sendWhatsApp}
                            >
                                WhatsApp
                            </button>
                            <button
                                className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
                                onClick={sendEmail}
                            >
                                Email
                            </button>
                        </div>
                        <button
                            className="mt-6 text-gray-500 hover:text-gray-700"
                            onClick={closePopup}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
export default Plans