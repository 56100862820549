

import { apiAuthenticatedRequest, apiRequest } from '../api/apiRequest'

export const testService = () => {
  return {
    checkExerciseMcqAnswer: (id,data, headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'POST',
        url: `questions/mcq/${id}/check-answer/`,
        data,
        headers
      })
    },
    checkExerciseSimulationAnswer: (id,headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'GET',
        url: `questions/simulation/${id}/answers/`,
        headers
      })
    },
    startExerciseTest: (questionType='mcq',id, headers = {}) => {
      let testType = questionType
      if(testType === 'long_answer'){
        testType = 'long-answer'
      }
      return apiAuthenticatedRequest({
        method: 'POST',
        url: `exams/exercise/${testType}/lecture/${id}/start/`,
        headers
      })
    },
    submitAnswer: (id,data,testType, headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'POST',
        url: `/exams/exercise/${testType}/${id}/submit/`,
        data,
        headers
      })
    },
    getSummary: (id,type, headers = {}) => {
      let testType = type
      if(testType === 'long_answer'){
        testType = 'long-answer'
      }
      return apiAuthenticatedRequest({
        method: 'GET',
        url: `/exams/exercise/${testType}/lecture/${id}/summary/`,
        headers
      })
    },
    resetSimulation:(id, headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'DELETE',
        url: `/exams/exercise/simulation/${id}/reset/`,
        headers
      })
    },

    checkLongAnswerQuestionAnswer: (id,headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'GET',
        url: `questions/long-answer/${id}/get-answer/`,
        headers
      })
      
    },
    
  }
}
