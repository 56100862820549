import React, { useState } from 'react'
import QuestionBankFilters from './QuestionBankFilters'
import { useNavigate } from 'react-router-dom';
import BackNavSection from '../../components/backNav/BackNavSection';
import CreateMCQ from '../dashboard/testManagement/CreateMCQ';
import CreateQuestionFilters from './CreateQuestionFilters';
import CreateSimulation from '../dashboard/testManagement/CreateSimulation';

const CreateQuestion = () => {
    const [selectedGrade, setSelectedGrade] = useState('');
    const [selectedModule, setSelectedModule] = useState('');
    const [selectedtype, setSelectedtype] = useState('mcq');

    const navigate = useNavigate()

    const navigateBack = () => {
        navigate('/question-bank')
    }

    const disableBtn = selectedModule.length === 0 || selectedGrade.length === 0
    console.log(disableBtn,'disableBtn')

    return (
        <div>
            <BackNavSection navigateBack={navigateBack} />
            <CreateQuestionFilters setSelectedtype={setSelectedtype} selectedtype={selectedtype} selectedGrade={selectedGrade} setSelectedGrade={setSelectedGrade} selectedModule={selectedModule} setSelectedModule={setSelectedModule} />
            <div className="flex w-full justify-end mt-12 px-14">
            <button 
            disabled={disableBtn}
            onClick={()=>navigate('/create-question-template',{state:{
                grade:selectedGrade,
                lecture:selectedModule,
                type:selectedtype
            }})} className={`p-4   mx-auto rounded w-64 text-[14px]  text-white font-bold ${disableBtn ? 'bg-gray-600':'bg-highlight hover:bg-hoverColor'}`}>Continue</button>
            </div>
        </div>
    )
}

export default CreateQuestion