import { Delete, RemoveRedEyeRounded } from '@mui/icons-material'
import React, { useState } from 'react'
import { stripHtmlTags, truncateString } from '../../utils/helpers'
import dayjs from 'dayjs'
import ConfirmationModal from '../../components/modal/ConfirmationModal'

const QuestionsTable = ({ list, onPreviewClick, onDeleteClick }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const handleConfirm = () => {
    console.log('Confirmed!');
    onDeleteClick(selectedId)
    handleClose(); // Close the modal after confirmation
  };
  return (
    <table className="min-w-full bg-white border border-gray-200">
      <thead>
        <tr>
          <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">No:</th>
          <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Question</th>
          <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Created at</th>
          <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700"></th>
          <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700"></th>
        </tr>
      </thead>
      {list?.length > 0 ? <tbody>
        { list.map((item, index) => (
          <tr key={item.id} className="hover:bg-gray-100">
            <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">{index + 1}</td>
            <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">{truncateString(stripHtmlTags(item.question), 120)}</td>
            <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">{item.created_at ? dayjs(item.created_at).format("DD-MM-YYYY") : dayjs(item.createdAt).format("DD-MM-YYYY")}</td>
            <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700 cursor-pointer" onClick={() => onPreviewClick(item.id)}>
              <RemoveRedEyeRounded />
            </td>
            <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700 cursor-pointer" onClick={() => {
              setSelectedId(item?.id)
              handleOpen()
            }}>
              <Delete />
            </td>
          </tr>
        )) }
      </tbody>:<div className='h-200 grid place-content-center'>No data</div>}
     
      <ConfirmationModal isOpen={isModalOpen}
        onClose={handleClose}
        onConfirm={handleConfirm}
        title="Confirm Delete" />
    </table>
  )
}

export default QuestionsTable