import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import CreateMCQ from '../dashboard/testManagement/CreateMCQ'
import CreateSimulation from '../dashboard/testManagement/CreateSimulation'
import BackNavSection from '../../components/backNav/BackNavSection'
import CreateLongAnswer from './CreateLongAnswer'

const CreateQuestiontemplateView = () => {
    const location = useLocation()
    const { lecture, grade, type } = location.state

    const renderTemplate = () => {
        if (type === 'mcq') {
            return <CreateMCQ lectureId={lecture} gradeId={grade} />
        } else if (type === 'simulation') {
            return <CreateSimulation lectureId={lecture} gradeId={grade} />
        } else {
            return  <CreateLongAnswer lectureId={lecture} gradeId={grade} />
        }
    }
    const navigate = useNavigate()

    const navigateBack = () => {
        navigate('/question-bank')
    }

   
    return (
        <div>
            <BackNavSection navigateBack={navigateBack} />
            {renderTemplate()}
        </div>
    )


}

export default CreateQuestiontemplateView