export const formatDateToYMD = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};


export function getYouTubeVideoId(url) {
  try {
      const urlObj = new URL(url);

      // Handle "youtu.be" short URLs
      if (urlObj.hostname === "youtu.be") {
          return urlObj.pathname.slice(1); // Remove leading slash
      }

      // Handle "youtube.com" or "www.youtube.com" URLs
      if (urlObj.hostname.includes("youtube.com")) {
          return urlObj.searchParams.get("v");
      }

      return null; // Not a valid YouTube URL
  } catch (error) {
      console.error("Invalid URL:", error);
      return null;
  }
}



export const stripHtmlTags=(input) =>{
  if (!input) return '';
  return input
      .replace(/<br\s*\/?>/gi, ' ') // Replace <br> or <br/> with a space
      .replace(/<\/?[^>]+(>|$)/g, '') // Remove all other HTML tags
      .trim(); // Trim leadi
}


export const truncateString = (str, limit) => {
  if (typeof str !== 'string') return '';
  return str.trim().length > limit
    ? str.trim().substring(0, limit) + '...'
    : str.trim();
};

export function isUpcomingDate(dateString) {
  const today = new Date(); // Current date
  const givenDate = new Date(dateString); // Convert the string to a Date object

  return givenDate > today;
}

