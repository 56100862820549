import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';

const ConfirmationModal = ({ isOpen, onClose, onConfirm, title, children }) => {
    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
            {/* Modal Title */}
            <DialogTitle className="font-semibold text-gray-700">
                {title || 'Are you sure?'}
            </DialogTitle>

            {/* Modal Content */}
            <DialogContent className="text-gray-600">
                {children || 'Please confirm your action.'}
            </DialogContent>

            {/* Modal Actions */}
            <DialogActions>
                <Button 
                    onClick={onClose} 
                    variant="outlined" 
                    color="secondary"
                >
                    Cancel
                </Button>
                <Button 
                    onClick={onConfirm} 
                    variant="contained" 
                    color="primary"
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationModal;
