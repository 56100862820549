import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';

const AnalyticsCard = ({ title, data }) => {
    const chartData = {
        labels: ['Attended', 'Correct'],
        datasets: [
            {
                data: [data?.attended, data?.correct],
                backgroundColor: ['#FF6384', '#36A2EB'],
                hoverBackgroundColor: ['#FF6384', '#36A2EB'],
            },
        ],
    };

    return (
        <div className="bg-white shadow-lg rounded-lg p-4 w-64  flex flex-col items-center text-center">
            <h4 className="text-lg font-bold mb-4">{title}</h4>
            <Doughnut data={chartData} />
            <div className="mt-4">
                <p className="text-sm">Attended: {data?.attended}</p>
                <p className="text-sm">Correct: {data?.correct}</p>
            </div>
        </div>
    );
};

const ProgressAnalytics = ({ data }) => {


    return (
        <div className="bg-white p-8 rounded-lg">
            <h2 className="text-2xl font-bold text-navy mb-8">Progress so far</h2>
            <div className="flex items-center gap-4">
                <div className="shadow-lg rounded-lg">
                    <h3 className="text-xl font-bold text-center mb-4">Practice Test</h3>
                    <div className="flex justify-start gap-4">
                        <AnalyticsCard title="MCQ" data={data?.practice.mcq} />
                        <AnalyticsCard title="Simulation" data={data?.practice.simulation} />
                    </div>
                </div>
                <div className='shadow-lg rounded-lg'>
                    <h3 className="text-xl font-bold text-center mb-4">Unit Test</h3>
                    <div className="flex justify-start gap-4">
                        <AnalyticsCard title="MCQ" data={data?.unit.mcq} />
                        <AnalyticsCard title="Simulation" data={data?.unit.simulation} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProgressAnalytics;
