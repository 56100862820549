import React, { useEffect, useState } from 'react'
import { dashboardService } from '../../service/dashboardService'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

const Profile = () => {
  const [user, setUser] = useState(null)
  const [isCopied, setIsCopied] = useState(false);
    const [list, setList] = useState(null)

  const fetchProfileData = async () => {
    try {
      const response = await dashboardService().getProfile()
      if(response?.status === 200){
        setUser(response?.data)

      }else{
        toast.error(response.message)
      }

    } catch (error) {
      console.log(error.message, 'error')
      toast.error(error.message)

    }
  }

   const fetchSubscriptionData = async () => {
          try {
              const response = await dashboardService().getStudentSubscriptions(user.id)
  
              if (response.status === 200) {
                  setList(response.data.results)
              }else{
                toast.error(response.message)
              }
          } catch (error) {
              toast.error('Failed to retireve list')
          }
  
      }

  useEffect(() => {
    fetchProfileData()
  }, [])


  useEffect(()=>{
    if(user?.id) fetchSubscriptionData()
  },[user])

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    });
  };


  if (!user) return <div>loading</div>
  return (
      <div className="bg-white shadow-lg rounded-lg px-8 py-10 w-full min-h-screen">
        {/* User Information */}
        <div className="flex items-center mb-8 border-b pb-6">
          <div className="h-20 w-20 rounded-full bg-blue-500 flex items-center justify-center text-3xl font-bold text-white">
            {user.first_name.charAt(0)}{user.last_name.charAt(0)}
          </div>
          <div className="ml-6">
            <h2 className="text-2xl font-bold text-gray-800">{user.first_name} {user.last_name}</h2>
            <p className="text-gray-600">{user.email}</p>
          </div>
        </div>

        {/* Main Information Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Personal Information */}
          <div className="bg-gray-100 p-6 rounded-lg shadow-inner">
            <h3 className="text-xl font-semibold text-blue-700 mb-4">Personal Information</h3>
            <p className="text-gray-700">
              <span className="font-medium">Grade:</span> {user.grade}
            </p>
            <p className="text-gray-700 mt-2">
              <span className="font-medium">School:</span> {user.school}
            </p>
            <p className="text-gray-700 mt-2">
              <span className="font-medium">WhatsApp Number:</span> {user.whatsapp_num}
            </p>
          </div>

          {/* Referral Information */}
          <div className="bg-gray-100 p-6 rounded-lg shadow-inner">
            <h3 className="text-xl font-semibold text-blue-700 mb-4">Referral Information</h3>
            <div className="flex items-center text-gray-700">
              <span className="font-medium">Referral Code:</span>
              <span className="ml-2 bg-gray-200 px-2 py-1 rounded">{user.referral_code}</span>
              <button
                onClick={() => copyToClipboard(user.referral_code)}
                className="ml-4 p-1 text-blue-500 hover:text-blue-700"
                title="Copy to clipboard"
              >
                <ContentCopyIcon />
              </button>
            </div>
            {isCopied && <p className="text-green-500 mt-2">Copied!</p>}
            <p className="text-gray-700 mt-2">
              <span className="font-medium">Referred Users:</span> {user.reffered_users}
            </p>
          </div>
        </div>

        {/* Approval Status */}
        <div className="mt-8">
          {user.is_subscribed ? (
            <span className="px-6 py-3 inline-block text-sm font-semibold rounded-full bg-green-100 text-green-800 shadow">
              Subscribed
            </span>
          ) : (
            <span className="px-6 py-3 inline-block text-sm font-semibold rounded-full bg-red-100 text-red-800 shadow">
              Free Plan
            </span>
          )}
        </div>
         <div className="overflow-x-auto bg-gray-50 rounded-lg shadow-sm p-4">
                        <table className="min-w-full table-auto border-collapse border border-gray-200">
                            <thead>
                                <tr>
                                    <th className="py-3 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">
                                        No:
                                    </th>
                                    <th className="py-3 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">
                                        Started Date
                                    </th>
                                    <th className="py-3 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">
                                        Plan
                                    </th>
                                    <th className="py-3 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">
                                        Expire Date
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {list?.length > 0 ? list.map((item, index) => {
                                    const { student } = item;
                                    const { plan } = item;
                                    return (
                                        <tr key={item.id} className="hover:bg-gray-100">
                                            <td className="py-3 px-4 border-b border-gray-200 text-sm text-gray-700">
                                                {index + 1}
                                            </td>
                                            <td className="py-3 px-4 border-b border-gray-200 text-sm text-gray-700">
                                            {dayjs(item.start_date).format('DD-MM-YYYY')}
                                            </td>
                                            <td className="py-3 px-4 border-b border-gray-200 text-sm text-gray-700">
                                                {plan.description}
                                            </td>
                                            <td className="py-3 px-4 border-b border-gray-200 text-sm text-gray-700">
                                                {dayjs(item.end_date).format('DD-MM-YYYY')}
                                            </td>
                                        </tr>
                                    );
                                }):<div>No Previous Records</div>}
                            </tbody>
                        </table>
                    </div>
      </div>

  );
};

export default Profile