import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { dashboardService } from '../../../service/dashboardService'
import { toast } from 'react-toastify'
import dayjs from 'dayjs';
import PrimaryButton from '../../../components/button/PrimaryButton';
import SubscriptionFormModal from '../../dashboard/subscriptions/SubscriptionForm';

const StudentDetail = () => {
    const { id } = useParams()
    const [data, setData] = useState(null)
    const [list, setList] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [showForm,setShowForm] = useState(false)
    

    const fetchData = async () => {
        setIsLoading(true)
        try {
            const response = await dashboardService().getStudentDetail(id)
            fetchSubscriptionData(id)

            if (response.status === 200) {
                setData(response.data)
                fetchSubscriptionData()
            }
            console.log(response.data, 'response')
        } catch (error) {
            toast.error('Failed to retireve list')
        }

    }

    const fetchSubscriptionData = async () => {
        setIsLoading(true)
        try {
            const response = await dashboardService().getStudentSubscriptions(id)
            setIsLoading(false)

            if (response.status === 200) {
                setList(response.data.results)
            }
            console.log(response.data, 'response subscriptions')
        } catch (error) {
            toast.error('Failed to retireve list')
        }

    }
    const onSubscriptionAdd =()=>{
        setShowForm(false)
        toast.success('Subscription Added')
        fetchData()
    }


    useEffect(() => {
        fetchData()
    }, [])


    const navigate = useNavigate()
    return (
        <div className="bg-white shadow-lg rounded-lg h-screen p-6">
        {/* Go Back Button */}
        <div className="mb-6">
            <button onClick={()=>navigate('/students-listing')} className="text-blue-600 hover:underline flex items-center gap-2">
                ← Go back
            </button>
        </div>
    
        {/* Student Information */}
        <div className="mb-8">
            <h2 className="text-3xl font-bold text-gray-800 mb-2">
                {data?.first_name} {data?.last_name}
            </h2>
            <h3 className="text-lg text-gray-600">
                {data?.school} - {data?.grade}th grade
            </h3>
        </div>
    
        {/* Additional Details */}
        <div className="mb-12 bg-gray-50 p-6 rounded-lg shadow-sm">
            <div className="flex flex-wrap gap-6">
                <div className="flex items-center gap-4 w-1/2">
                    <p className="text-sm font-semibold text-gray-700">Joined On:</p>
                    <p className="text-sm text-gray-600">
                        {dayjs(data?.created_at).format('DD / MM / YYYY')}
                    </p>
                </div>
                <div className="flex items-center gap-4 w-1/2">
                    <p className="text-sm font-semibold text-gray-700">Email:</p>
                    <p className="text-sm text-gray-600">{data?.email}</p>
                </div>
                <div className="flex items-center gap-4 w-1/2">
                    <p className="text-sm font-semibold text-gray-700">WhatsApp No:</p>
                    <p className="text-sm text-gray-600">{data?.whatsapp_num}</p>
                </div>
                <div className="flex items-center gap-4 w-1/2">
                    <p className="text-sm font-semibold text-gray-700">Referrals:</p>
                    <p className="text-sm text-gray-600">{data?.reffered_users}</p>
                </div>
            </div>
        </div>
    
        {/* Subscriptions Section */}
        <div className="mb-12">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold text-gray-800">Subscriptions</h2>
                {data?.is_subscribed ? (
                    <div className="text-sm font-medium text-green-600 bg-green-100 px-3 py-1 rounded">
                        Active Subscription
                    </div>
                ) : (
                    <PrimaryButton onClick={() => setShowForm(true)}>
                        Add Subscription
                    </PrimaryButton>
                )}
            </div>
    
            {/* Subscriptions Table */}
            <div className="overflow-x-auto bg-gray-50 rounded-lg shadow-sm p-4">
                <table className="min-w-full table-auto border-collapse border border-gray-200">
                    <thead>
                        <tr>
                            <th className="py-3 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">
                                No:
                            </th>
                            <th className="py-3 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">
                                Started Date
                            </th>
                            <th className="py-3 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">
                                Plan
                            </th>
                            <th className="py-3 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">
                                Expire Date
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {list?.length > 0 ? list.map((item, index) => {
                            const { student } = item;
                            const { plan } = item;
                            return (
                                <tr key={item.id} className="hover:bg-gray-100">
                                    <td className="py-3 px-4 border-b border-gray-200 text-sm text-gray-700">
                                        {index + 1}
                                    </td>
                                    <td className="py-3 px-4 border-b border-gray-200 text-sm text-gray-700">
                                    {dayjs(item.start_date).format('DD-MM-YYYY')}
                                    </td>
                                    <td className="py-3 px-4 border-b border-gray-200 text-sm text-gray-700">
                                        {plan.description}
                                    </td>
                                    <td className="py-3 px-4 border-b border-gray-200 text-sm text-gray-700">
                                        {dayjs(item.end_date).format('DD-MM-YYYY')}
                                    </td>
                                </tr>
                            );
                        }):<div>No Previous Records</div>}
                    </tbody>
                </table>
            </div>
        </div>
        <SubscriptionFormModal isOpen={showForm} onClose={()=>setShowForm(false)} onSuccess={onSubscriptionAdd} studentId={data?.id} />
    </div>
    )
}

export default StudentDetail