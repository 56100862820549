import React from 'react'
import { useLocation } from 'react-router-dom'
import YouTube from 'react-youtube'
import Breadcrumb from '../../components/breadCrumbs/BreadCrumbs'
import { getYouTubeVideoId } from '../../utils/helpers'

const LectureView = () => {
    const location = useLocation()
    const state = location?.state
    console.log(state, 'stta')
    const opts = {
        height: '500',
        width: '900',
        playerVars: {
            autoplay: 1,
        },
    };

    const breadcrumbRoutes = [
        { path: '/study', label: 'Study' },
        { path: '/', label: state?.title },
    ];

    return (
        <div className='font-playfair'>
            <Breadcrumb routes={breadcrumbRoutes} />
            <div className="rounded flex itemc-center justify-center">
                <YouTube videoId={getYouTubeVideoId(state?.url)} opts={opts} />
            </div>
        </div>
    )
}

export default LectureView