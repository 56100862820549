import React from 'react'
import { CalendarViewDay, CalendarViewMonthOutlined, CalendarViewMonthRounded, List } from '@mui/icons-material';
import ModalInput from '../../../components/modal/ModalInput';
import PrimaryButton from '../../../components/button/PrimaryButton';
import RichTextEditor from '../../../components/richTextEditor/RichTextEditor';


const SimulationTableSetup = ({ rows, columnCount, isLoading, isModalOpen, content, handleCloseModal, handleEditorChange, handleSaveModal, onSubmit, handleCellClick, selectedCell }) => {
    console.log(rows, 'rows inside table')
    const firstRow = rows[0]
    const firstRowArr = Object.entries(firstRow)
    console.log(firstRowArr,'firstRowArr')

 
    return (
        <div className='bg-primary p-4 rounded'>
            <label className="block text-sm font-medium text-gray-700 mb-4">Enter Question :</label>

            <div className="w-1/2 h-[100px] mb-16">
                <RichTextEditor value={content} onChange={handleEditorChange} />
            </div>
            <table border="1" className='overflow-x-scroll !w-full block mb-8'>
                <thead>
                    <tr>
                        <th></th>
                        {/* {Array.from({ length: columnCount }).map((_, index) => (
                            <th key={index} className='min-w-52  p-2 border border-gray-300'>{String.fromCharCode(65 + index)}</th>
                        ))} */}
                        {firstRowArr.map((i,index) =>   <th key={index} className='min-w-52  p-2 border border-gray-300'>{i[1].columnName}</th>
                            )}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            <td className='border border-gray-300'>{rowIndex + 1}</td>
                            {Array.from({ length: columnCount }).map((_, colIndex) => {
                                const columnType = String.fromCharCode(97 + colIndex);
                                const correctOption = row[columnType]?.correctOption
                                return (
                                    <td
                                        key={colIndex}
                                        className='min-w-52 p-2 border border-gray-300 cursor-pointer relative'
                                        style={{ border: row[columnType].isQuestion ? '2px solid red' : '' }}
                                        onClick={() => handleCellClick(row[columnType])}
                                    >
                                        {row[columnType].isQuestion && row[columnType].questionType === 'text' ? row[columnType].options[correctOption].value : row[columnType].value}
                                        {row[columnType].isQuestion && <div className="w-4 h-4 rounded bg-gray-400 absolute top-2 right-2 grid place-content-center">
                                            {row[columnType].isQuestion && row[columnType].questionType === 'date' ? <CalendarViewMonthOutlined  style={{ color: '#fff', fontSize: '12px' }}/> :null}
                                            {row[columnType].isQuestion && row[columnType].questionType === 'text' ? <List  style={{ color: '#fff', fontSize: '12px' }}/> :null}
                                            {row[columnType].isQuestion && row[columnType].questionType === 'number' ?  <p className='text-white font-bold text-[8px]'>123</p> :null}
                                        </div>}
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>

            <PrimaryButton
                className="block w-1/6 px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                type="submit"
                isLoading={isLoading}
                onClick={() => onSubmit()}
            >
                Submit
            </PrimaryButton>

            {selectedCell ? (
                <ModalInput
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    onSave={handleSaveModal}
                    cellData={selectedCell}
                />
            ) : null}
        </div>
    )
}

export default SimulationTableSetup