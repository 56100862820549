import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Modal } from '@mui/material'
import PrimaryButton from '../../../components/button/PrimaryButton'
import { practiceTestService } from '../../../service/practiceTestService'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import McqQuestionWithSolution from '../questionsWithSolutions/McqQuestionWithSolution'
import SimulationWithSolution from '../questionsWithSolutions/SimulationWithSolution'

const PracticetestSolutionModal = ({ isOpen, handleClose,onPrevious,onNext,currentQuestionId,currentQuestionIndex,totalQues,questionType }) => {
    const [data, setData] = useState(null)
console.log(currentQuestionIndex,'currentQuestionIndex')
console.log('currentQuestionId',currentQuestionId)

    // const {id} =  useParams()
    const navigate = useNavigate()

    const fetchData = async () => {
        console.log('fetch ing solution',currentQuestionId)
        try {
            const response = await practiceTestService().getPracticeTestSolutionById(currentQuestionId)
            console.log(response,'res')
            setData(response?.data)
        } catch (error) {
            console.log(error.message,'error')
        }
    }

    const navigateBack = ()=>{
        navigate('/modules')
    }

    useEffect(()=>{
        if(isOpen){
            fetchData()
        }
    },[isOpen,currentQuestionId])

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 900,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    return (
        <Modal open={isOpen} onClose={handleClose}>
            <div style={style} className="modal-content w-full relative !pt-[40px]">
                <div className="flex items-center gap-4">
                <h2 className="text-xl font-bold mb-4 text-amber-500">{currentQuestionIndex + 1}/{totalQues}</h2>
                <h2 className="text-xl font-bold mb-4">Solutions for the test taken !</h2>

                </div>
                <div className="border border-gray-300 mb-4   ">
                    {questionType === 'MCQ' ?  <McqQuestionWithSolution data={data} />:<SimulationWithSolution data={data?.cell_data} />}
                </div>
               <div className="flex ">
               <button onClick={onPrevious}>
                    <ChevronLeft />
                </button>
                <button onClick={onNext}>
                    <ChevronRight />
                </button>
                <PrimaryButton onClick={handleClose}>Exit</PrimaryButton>
               </div>
            </div>
        </Modal>
    )
}

export default PracticetestSolutionModal