// src/components/LandingPage.js
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const LandingPage = () => {
  const navigate = useNavigate()
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return (
    <div className=" bg-secondary min-h-[90vh] w-full grid place-content-center">
      <div className="grid place-content-center  h-[80vh] text-center p-8 rounded-[40px] w-[80vw] mx-auto bg-navyBlue text-white">
        <h2 className='text-[62px] font-playfair font-bold mb-8 text-center'>Join the New Generation <div className="flex items-center gap-x-2 pl-12"><p>Learning </p><p className='text-gold'>{'  '}Experience</p></div> </h2>
        <p className="text-[20px] font-bold">Easy way to way ace your CBSE  <br/> accountancy Exam</p>
        <p className='text-[20px] font-bold'>+</p>
        <p className='text-[20px] mb-12 font-bold'>bonus life lessons on money, health, <br/> relationships, career and more</p>
{isAuthenticated ? <button onClick={() => navigate('/dashboard')} className='grid group/button place-content-center p-4 rounded w-1/2 mx-auto bg-white text-black hover:bg-hoverColor flex gap-2'>
          <p className="text-black text-center text-[22px] group-hover/button:text-white flex gap-1">Proceed to <p className=''>Dashboard</p></p>
          {/* <ArrowRightAltSharp style={{ color: '#000' }} /> */}
        </button> : <button onClick={() => navigate('/register')} className='grid group/button place-content-center p-4 rounded w-1/2 mx-auto bg-white text-black hover:bg-hoverColor flex gap-2'>
          <p className="text-black text-center text-[22px] group-hover/button:text-white flex gap-1">Get started for <p className=''>free</p></p>
          {/* <ArrowRightAltSharp style={{ color: '#000' }} /> */}
        </button> }
       
      </div>
    </div>
  );
}

export default LandingPage;
