import React, { useState } from 'react'
import BackNavSection from '../../components/backNav/BackNavSection'
import { useLocation, useNavigate } from 'react-router-dom'
import QuestionsTable from './QuestionsTable';
import MCQListing from './MCQListing';
import SimulationListing from './SimuationListing';
import LongAnswerListing from './LongAnswerListing';

const QuestionbankListing = () => {
    const [activeTab, setActiveTab] = useState('mcq');


    const navigate = useNavigate()

    const navigateBack = () => {
        navigate('/question-bank')
    }


const location = useLocation()
const { lecture,grade } = location.state;


    return (
        <div>
            <BackNavSection navigateBack={navigateBack} />
            <div className="flex font-bold">
                Grade {grade?.grade} / {lecture?.title}
            </div>
            <div className="flex border-b">
                <button
                    className={`w-full py-2 text-center ${activeTab === 'mcq' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'
                        }`}
                    onClick={() => setActiveTab('mcq')}
                >
                    MCQ
                </button>
                <button
                    className={`w-full py-2 text-center ${activeTab === 'long_answer' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'
                        }`}
                    onClick={() => setActiveTab('long_answer')}
                >
                    Long answer
                </button>
                <button
                    className={`w-full py-2 text-center ${activeTab === 'simulation_table' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'
                        }`}
                    onClick={() => setActiveTab('simulation_table')}
                >
                    Simulation Table
                </button>
            </div>
            <div className="flex ">
               {activeTab === 'mcq' ?   <MCQListing grade={grade?.id}  lecture={lecture?.id}/> : null}
               {activeTab === 'simulation_table' ?   <SimulationListing grade={grade?.id}  lecture={lecture?.id}/> : null}
               {activeTab === 'long_answer' ?   <LongAnswerListing grade={grade?.id}  lecture={lecture?.id} /> : null}
            </div>
        </div>
    )
}

export default QuestionbankListing