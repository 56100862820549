import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { questionBankService } from '../../../service/questionBankService'
import { testService } from '../../../service/testService'
import { showAnswer } from '../../../redux/exerciseTestSlice'
import { answersSchema } from '../../../utils/schema'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { Flag } from '@mui/icons-material'

const LongAnswerExerciseView = ({ id, setCorrectAnswer, correctAnswer, setSelectedOption, selectedOption, testType, setShwCorrectAnswerNow, shwCorrectAnswerNow }) => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [checkingAnswer, setCheckingAnswer] = useState(false)
    const isShowAnswer = useSelector((state) => state.exercise.showAnswer);

    const { control, watch, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(answersSchema),
        defaultValues: {
            answer1: ''
        },
    });

    const watchAnswer1 = watch('answer1')


    const fetchData = async () => {
        setLoading(true)
        try {
            const response = await questionBankService().getLongAnsweDetails(id)
            setLoading(false)
            console.log(response, 'res')
            if (response?.status === 200) {
                setData(response?.data)
            }
            console.log('res', response)
        } catch (error) {
            console.log('error', error.message)
        }
    }

    const submitAnswer = async () => {
        setCheckingAnswer(true)

        let submitData = {
            answer: watchAnswer1
        }
        try {
            const response = await testService().submitAnswer(id, submitData, 'long-answer')
            setCheckingAnswer(false)
            console.log(response, 'res')
            // if (response?.status === 200) {

            // }
            console.log('res submi  answer', response)
        } catch (error) {
            console.log('error submit answer', error.message)
        }
    }

    const dispatch = useDispatch()
    const checkAnswer = async () => {
        console.log('checking answer')
        dispatch(showAnswer(false))
        const response = await testService().checkLongAnswerQuestionAnswer(id)
        if (response?.status === 200) {
            setCorrectAnswer(response?.data?.answers)
            setShwCorrectAnswerNow(true)
            submitAnswer()
        } else {
            console.log('error')
        }

    }

    useEffect(() => {
        console.log(isShowAnswer, 'show answer')
        if (isShowAnswer) checkAnswer()
    }, [isShowAnswer])

    useEffect(() => {
        if (id) fetchData()
    }, [id])

    const onSubmit = (data) => {

    }
    return (
        <div className="flex bg-gray-200">
            <div className="w-2/3 bg-white border rounded p-2 h-[65vh] overflow-auto relative" >
                <div onClick={() => { }} className='absolute cursor-pointer  border border-gray-400 rounded-full  grid place-content-center w-8 h-8 top-0 right-4 hover:bg-gray-200'>
                    <Flag style={{ color: 'green', fontSize: '10px' }} />
                </div>
                <div className=" mt-10 p-5 ">
                    <h2 className="text-2xl font-bold mb-5"></h2>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <p className="text-xl">{data?.question}</p>
                        <div className="my-12">
                            <label className="block text-sm font-medium text-gray-700 mb-1">Answer</label>
                            <Controller
                                name="answer1"
                                control={control}
                                render={({ field }) => (
                                    <textarea
                                        {...field}
                                        type="text"
                                        className={`bg-secondary w-full h-48 p-2 text-left align-top ${errors.answer1 ? 'border-red-500' : 'border-gray-300'} rounded`}
                                    />
                                )}
                            />
                            {errors.answer1 && <p className="text-red-500 text-sm mt-1">{errors.answer1.message}</p>}
                        </div>
                    </form>

                </div>
            </div>
        </div>
    )
}

export default LongAnswerExerciseView