import React, { useEffect, useState } from 'react'
import QuestionBankFilters from './QuestionBankFilters';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../components/button/PrimaryButton';
import { dashboardService } from '../../service/dashboardService';

const QuestionBank = () => {

    const [selectedGrade, setSelectedGrade] = useState('');
    const [data, setData] = useState([]);
    const [isLoading, setisLoading] = useState(false);

    const navigate = useNavigate()

    const fetchLectures = async () => {
        setisLoading(true)
        try {
            const response = await dashboardService().getLecturesBasedOnGrade(selectedGrade)
            setisLoading(false)
            if (response?.status === 200) {
                setData(response?.data?.results)
            }
        } catch (error) {
            console.log(error.message, 'error')
        }
    }

    useEffect(() => {
        if (selectedGrade) fetchLectures()
    }, [selectedGrade])

    return (
        <div>
            <div className="flex justify-between">
                <h2 className='mb-8 font-bold text-xl'>Question Bank</h2>
                <PrimaryButton onClick={() => navigate('/create-question')}>Create Question</PrimaryButton>
            </div>
            <QuestionBankFilters setSelectedGrade={setSelectedGrade} selectedGrade={selectedGrade} />
            <div className='flex justify-start gap-4'>
                {data?.map(i => <div key={i?.id} className="w-[25%] border-[2px] border-gray-400 hover:bg-gray-100  rounded-xl p-8 cursor-pointer relative overflow-hidden" onClick={() => navigate('/question-listing', {
                    state: { lecture: { title: i.title, id: i.id }, grade: i.grade }
                })}>
                    <div className='w-24 h-8 absolute top-0 right-0 bg-gray-600 text-white text-center font-bold'  >Grade {i?.grade?.grade}</div>
                    <h3 className='text-xl font-bold mb-4'>{i?.title}</h3>
                    <div className='flex flex-wrap gap-4'>
                        <p className='text-sm'>MCQ ({i?.total_mcq})</p>
                        <p className='text-sm'>Long answer ({i?.total_long_answer})</p>
                        <p className='text-sm'>Simulation Table ({i?.total_simulation})</p>
                    </div>
                </div>)}
            </div>
        </div>
    )
}

export default QuestionBank