import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/authSlice';
import { useNavigate } from 'react-router-dom';
import { AccountBoxRounded, AccountCircleOutlined } from '@mui/icons-material';
import Logo from '../logo/Logo';

const Header = ({ user }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleProfileClick = () => {
        setDropdownOpen(prev => !prev);
    };

    const handleLogout = () => {
        setDropdownOpen(prev => !prev);
        dispatch(logout())
        navigate('/')
    };

    return (
        <nav className="bg-primary text-white px-4 py-2 flex justify-between items-center">
            <div className="flex space-x-4">
                <Logo onClick={() => navigate('/')} />
            </div>
            <div className="relative flex items-center gap-4">
                {user.role === 'student' ? <div onClick={() => navigate('/plans')} className='w-24 h-8 border-gold border-2 rounded-xl cursor-pointer grid place-content-center text-center text-gold hover:bg-gold hover:text-white font-bold cursor-pointer'>Upgrade</div> : null}

                <button onClick={handleProfileClick} className="focus:outline-none w-12 h-12">
                    <AccountCircleOutlined style={{ fontSize: '40px', color: '#030351' }} />
                </button>
                {dropdownOpen && (
                    <div className="absolute right-0 mt-2 w-48 bg-white text-black rounded shadow-lg">
                        <button
                            onClick={handleLogout}
                            className="block px-4 py-2 hover:bg-gray-100 w-full text-left"
                        >
                            Logout
                        </button>
                    </div>
                )}
            </div>
        </nav>
    )
}

export default Header