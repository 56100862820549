import React, { useState, useEffect } from 'react';

const Timer = ({ start = false, stop = false }) => {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (!start || stop) return; // Exit if not started or if stopped

    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds + 1);
    }, 1000);

    // Cleanup interval on component unmount or when 'start' or 'stop' changes
    return () => clearInterval(interval);
  }, [start, stop]); // Dependency array includes 'start' and 'stop'

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  return (
    <div>
      <h1>Time Elapsed: {formatTime(seconds)}</h1>
    </div>
  );
};

export default Timer;
