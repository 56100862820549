import * as React from 'react';

function CircularProgressWithLabel({
  value , // current progress value
  size ,  // total size (maximum value)
  label = "Progress",
  diameter = 140, // diameter of the circle in pixels
  color = "#3b82f6" 
}) {
  const progressPercentage = (value / size) * 100; // calculate percentage based on the proportion
  
  const radius = (diameter - 10) / 2; // Adjust radius (subtracting stroke width to fit)
  const circumference = 2 * Math.PI * radius; // Full circumference of the circle
  const strokeDashoffset = circumference - (circumference * (progressPercentage / 100)); // Adjust offset for progress

  return (
    <div className="relative flex items-center justify-center" style={{ width: diameter, height: diameter }}>
      {/* Background full circle */}
      <svg className="absolute transform rotate-0" width={diameter} height={diameter}>
        <circle
          cx={diameter / 2}
          cy={diameter / 2}
          r={radius}
          stroke="#e5e7eb" // Tailwind's gray-300 color
          strokeWidth="10"
          fill="none"
        />
      </svg>

      {/* Progress circle */}
      <svg className="absolute transform rotate-0" width={diameter} height={diameter}>
        <circle
          cx={diameter / 2}
          cy={diameter / 2}
          r={radius}
          stroke={color}// Tailwind's blue-500 color
          strokeWidth="10"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          fill="none"
          style={{ transition: "stroke-dashoffset 0.5s ease" }}
        />
      </svg>

      {/* Text in the center */}
      <div className="absolute flex flex-col items-center justify-center">
        <span className="text-lg font-bold">{label}</span>
        <span className="text-sm text-gray-500">{value ?  `${Math.round(progressPercentage)}%`:'0'}</span>
      </div>
    </div>
  );
}

export default CircularProgressWithLabel;
