import React, { useEffect, useState } from 'react'
import PrimaryButton from '../../../components/button/PrimaryButton'
import { ChevronLeft, ChevronRight, Flag } from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'
import Timer from '../../../components/timer/Timer'
import SimulationUnitTestView from './SimulationUnitTestView'
import McqUnitTestView from './McqUnitTestView'
import UnitTestResultModal from './UnitTestResultModal'
import UnitTestSolutionModal from './UnitTestSolutionModal'
import { unitTestService } from '../../../service/unitTestService'
import { toast } from 'react-toastify'

function UnitTestLayout() {
    const location = useLocation();
    const { testType } = location.state;
    const [questions, setQuestions] = useState([])
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
    const [currentSolutionIndex, setCurrentSolutionIndex] = useState(0)
    const [selectedOption, setSelectedOption] = useState(null)
    const [istimerRunning, setistimerRunning] = useState(false)
    const [correctAnswer, setCorrectAnswer] = useState(null)
    const { question_type, lecture_ids } = location.state


    const navigate = useNavigate()

    const [showResultModal, setShowResultModal] = useState(false)
    const [showSolutionsModal, setShowSolutionsModal] = useState(false)

    const handleResultModalClose = () => {
        setShowResultModal(false)
        navigate('/unit-test')
    }

    const startTest = async () => {
        let data = {
            lecture_ids: lecture_ids,
        }
      try {
        const response = await unitTestService().startUnitTest(data)
        if (response?.status === 200) {
            fetchQuestions()
        } else {
            console.log('error start')
            toast.error(response.message)
        }
      } catch (error) {
        toast.error(error.message)
      }
    }

    const handleShowSolution = () => {
        setShowResultModal(false)
        setShowSolutionsModal(true)
    }

    const fetchQuestions = async () => {
        const response = await unitTestService().getUnitTestQuestions()
        console.log(response, 'response list')
        try {
            if (response?.status === 200) {
                setQuestions(response?.data?.question_ids)
                setistimerRunning(true)
            }  else {
                console.log('error start')
                toast.error(response.message)
            }
          } catch (error) {
            toast.error(error.message)
          }
    }

       const stopTestAndExit = async () => {
            try {
                const response = await unitTestService().getUnitTestSummary();
                console.log('Test summary fetched:', response);
            } catch (error) {
                console.error('Error fetching test summary:', error);
                toast.error(error.message)
            }
        };


    useEffect(() => {
        if(lecture_ids?.length > 0)startTest()
    }, [lecture_ids])

      useEffect(() => {
            // Return a cleanup function to run only on unmount
            return () => {
                stopTestAndExit(); // This will run when the component unmounts
            };
        }, []);

    return (
        <div className=' relative'>
            <div className="flex w-full justify-between items-center  border-b-2 p-2">
                {/* <p className="text-xl font-bold">{lecture} - {testType} </p> */}
                <Timer start={istimerRunning} stop={!istimerRunning} />
                <PrimaryButton onClick={() => {
                    setistimerRunning(false)
                    setShowResultModal(true)
                }} type="button" className='text-white bg-highlight' >
                    Exit
                </PrimaryButton>
            </div>
            <div className='flex items-center justify-start p-4 gap-52'>
                <p className={`text-xl  text-amber-500 font-bold `}>{currentQuestionIndex + 1}/{questions?.length} </p>
            </div>
            {questions[currentQuestionIndex]?.type === 'mcq' ? <McqUnitTestView setSelectedOption={setSelectedOption} selectedOption={selectedOption} testType={questions[currentQuestionIndex]?.type} id={questions[currentQuestionIndex]?.id} /> : <SimulationUnitTestView setCorrectAnswer={setCorrectAnswer} answersObject={correctAnswer} setAnswersForSubmitting={setSelectedOption} answersForSubmitting={selectedOption} testType={questions[currentQuestionIndex]?.type} id={questions[currentQuestionIndex]?.id} />}

            {/* {testType === 'long_answer' && <LongAnswerExerciseView testType={testType} setCorrectAnswer={setCorrectAnswer} correctAnswer={correctAnswer} setSelectedOption={setSelectedOption} selectedOption={selectedOption} id={questions[currentQuestionIndex]?.id} shwCorrectAnswerNow={shwCorrectAnswerNow} setShwCorrectAnswerNow={setShwCorrectAnswerNow} />} */}
            <div className='flex items-center justify-center gap-2'>
                <div onClick={() => {
                    if (currentQuestionIndex != 0) {
                        setCorrectAnswer(null)
                        setSelectedOption(null)
                        setCurrentQuestionIndex(prev => prev - 1)
                    }
                }} className='cursor-pointer border p-4 rounded bg-gray-100'>
                    <ChevronLeft />
                </div>
                <div
                    onClick={() => {
                        if (currentQuestionIndex + 1 != questions.length) {
                            setCorrectAnswer(null)
                            setSelectedOption(null)
                            setCurrentQuestionIndex(prev => prev + 1)
                        }
                    }}
                    className='cursor-pointer  border p-4 rounded'>
                    <ChevronRight />
                </div>
            </div>
            <UnitTestResultModal isOpen={showResultModal} handleClose={handleResultModalClose} handleShowSolution={handleShowSolution} />
            <UnitTestSolutionModal questionType={questions[currentSolutionIndex]?.type} currentQuestionIndex={currentSolutionIndex} onPrevious={() => {
                if (currentSolutionIndex != 0) {
                    setCurrentSolutionIndex(prev => prev - 1)
                }
            }
            }
                onNext={() => {
                    if (currentSolutionIndex + 1 != questions.length) {
                        setCurrentSolutionIndex(prev => prev + 1)
                    }
                }
                }
                currentQuestionId={questions[currentSolutionIndex]?.id} isOpen={showSolutionsModal} handleClose={() => {
                    setShowResultModal(false)
                    setShowSolutionsModal(false)
                    navigate('/unit-test')
                }} totalQues={questions?.length} />
        </div>
    )
}

export default UnitTestLayout