import React from 'react';
import { Modal, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';

const WarningModal = ({ isOpen, onClose, onProceed }) => {
    return (
        <Modal open={isOpen} onClose={onClose}>
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto mt-20">
                {/* Modal Title */}
                <DialogTitle className="text-lg font-bold text-gray-800">
                    Warning Before Starting Test
                </DialogTitle>

                {/* Modal Content */}
                <DialogContent className="text-gray-600 mt-4">
                    <p>
                        Once you start the test, navigating away, refreshing the page, or closing the tab
                        will result in abandoning the test. Any progress made up to that point will be
                        taken as your final result.
                    </p>
                    <p className="mt-2 font-semibold text-red-500">
                        Please make sure you're ready before proceeding.
                    </p>
                </DialogContent>

                {/* Modal Actions */}
                <DialogActions className="mt-6 flex justify-end space-x-4">
                    <Button
                        onClick={onClose}
                        variant="outlined"
                        className="text-gray-700 hover:bg-gray-100"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={onProceed}
                        variant="contained"
                        color="primary"
                        className="bg-indigo-600 hover:bg-indigo-700"
                    >
                        Proceed
                    </Button>
                </DialogActions>
            </div>
        </Modal>
    );
};

export default WarningModal;
