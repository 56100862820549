import React, { useEffect, useState } from 'react'
import { publicService } from '../../service/publicService';
import { dashboardService } from '../../service/dashboardService';
import { toast } from 'react-toastify';

const CreateQuestionFilters = ({ selectedGrade, setSelectedGrade,selectedModule,setSelectedModule ,setSelectedtype
 , selectedtype}) => {
    const [grades, setGrades] = useState([]);
    const [modules, setModules] = useState([]);
    const [isLoading, setisLoading] = useState([]);
    
    const fetchGradesList = async () => {
        try {
            const response = await publicService().getGradesList()
            if (response?.status === 200) {
                setGrades(response?.data?.results)
            }
            console.log('grades', response)
        } catch (error) {
            console.log('error', error.message)
        }
    }

    const fetchLectures = async () => {
      setisLoading(true)
      try {
          const response = await dashboardService().getLecturesBasedOnGrade(selectedGrade)
          setisLoading(false)
          if (response?.status === 200) {
            setModules(response?.data?.results)
          }else{
            toast.error(response.message)
          }
      } catch (error) {
          console.log(error.message, 'error')
          toast.error(error.message)
      }
  }

  useEffect(() => {
      if (selectedGrade) fetchLectures()
  }, [selectedGrade])

    useEffect(() => {
        fetchGradesList()
    }, [])

    return (
            <div className="flex flex-col justify-start items-start gap-8 p-4">
              <div className="w-1/4">
                <label htmlFor="grade" className="block text-sm font-medium text-gray-700">Select Grade</label>
                <select
                    id="grade"
                    className="mt-1 border block w-full pl-3 pr-10 py-2 text-base border-gray-300 "
                    value={selectedGrade}
                    onChange={(e) => setSelectedGrade(e.target.value)}
                >
                    <option value="">Select a grade</option>
                    {grades.map((grade) => (
                        <option key={grade.id} value={grade.id}>
                            {grade.grade}
                        </option>
                    ))}
                </select>
              </div>
              <div className="w-1/4">
                <label htmlFor="lectures" className="block text-sm font-medium text-gray-700">Lectures</label>
                <select
                    id="lectures"
                    className="mt-1 border block w-full pl-3 pr-10 py-2 text-base border-gray-300 "
                    value={selectedModule}
                    onChange={(e) => setSelectedModule(e.target.value)}
                >
                    <option value="">Select a lecture</option>
                    {modules.map((lecture) => (
                        <option key={lecture.id} value={lecture.id}>
                            {lecture.title}
                        </option>
                    ))}
                </select>
              </div>
              <div className="w-1/4">
                <label htmlFor="question_type" className="block text-sm font-medium text-gray-700">Question Type</label>
                <select 
                 value={selectedtype}
                 onChange={(e) => setSelectedtype(e.target.value)}
                id="question_type" name="question_type" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  <option value="mcq">Multiple Choice</option>
                  <option value="long_answer">Long Answer</option>
                  <option value="simulation">Simulation Table</option>
                </select>
              </div>
            </div>
        
        
    )
}

export default CreateQuestionFilters