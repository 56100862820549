import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Modal } from '@mui/material'
import PrimaryButton from '../../../components/button/PrimaryButton'
import { testService } from '../../../service/testService'

const ResultModal = ({ isOpen, handleClose,lectureId,type }) => {
    const [data, setData] = useState(null)

    // const {id} =  useParams()
    const navigate = useNavigate()

    const fetchData = async () => {
        try {
            const response = await testService().getSummary(lectureId,type)
            console.log(response,'res')
            setData(response?.data)
        } catch (error) {
            console.log(error.message,'error')
        }
    }

    const navigateBack = ()=>{
        navigate('/modules')
    }

    useEffect(()=>{
        if(isOpen){
            fetchData()
        }
    },[isOpen])
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    return (
        <Modal open={isOpen} onClose={handleClose}>
            <div style={style} className="modal-content w-full relative !pt-[40px]">
                <h2 className="text-xl font-bold mb-4">Result</h2>
                <div className="flex mb-4   ">
                    <div className='border rounded p-4'>
                        <h3 className='text-xl font-bold'>Attended</h3>
                        <p className="text-lg font-bold text-amber-500">{data?.attempted_questions}/{data?.total_questions}</p>
                    </div>
                    <div className='border rounded p-4'>
                        <h3 className='text-xl font-bold'>Score</h3>
                        <p className="text-lg font-bold text-amber-500">{data?.score}</p>
                    </div>
                    <div className='border rounded p-4'>
                        <h3 className='text-xl font-bold'>Correct </h3>
                        <p className="text-lg font-bold text-green-500">{data?.correct_answers}/{data?.total_questions}</p>
                    </div>
                </div>
                <PrimaryButton onClick={handleClose}>Exit</PrimaryButton>
            </div>
        </Modal>
    )
}

export default ResultModal