import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ChevronLeft } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { dashboardService } from '../../service/dashboardService';
import { toast } from 'react-toastify';
import { formatDateToYMD, isUpcomingDate } from '../../utils/helpers';
import GradeSelectInput from '../landing/signupForm/GradeSelectInput';
import BackNavSection from '../../components/backNav/BackNavSection';

const schema = yup.object().shape({
    sort_order: yup.number().required('Module no is required'),
    title: yup.string().required('Title is required').max(255, 'Title must be at most 255 characters').min(1, 'Title must be at least 1 character'),
    description: yup.string().nullable(),
    video_url: yup.string().url('Must be a valid URL').required('Video URL is required').max(200, 'Video URL must be at most 200 characters').min(1, 'Video URL must be at least 1 character'),
    unlocking_date: yup.date().nullable(),
    grade: yup.number().nullable(),
});

const ModuleEditView = () => {
    const [unlockingDateInServer, setUnlockingDate] = useState(null)

    const { register, handleSubmit, setValue, getValues, formState: { errors }, control, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const navigate = useNavigate()
    const { id } = useParams()

    const fetchData = async () => {
        try {
            const response = await dashboardService().getLectureDetails(id)
            console.log(response.data, 'res')
            if (response?.status === 200) {
                // setData(response?.data)
                const { data } = response
                setValue('title', data?.title)
                setValue('video_url', data?.video_url)
                setValue('description', data?.description)
                setValue('unlocking_date', data?.unlocking_date)
                setValue('grade', data?.grade?.id)
                setValue('sort_order', data?.sort_order)
                setUnlockingDate(data?.unlocking_date)

            } else {
                toast.error(response?.message)
            }
        } catch (error) {
            console.log(error.message, 'error')
            toast.error(error?.message)
        }
    }



    const navigateBack = () => navigate('/modules')

    const onSubmit = async data => {
        if (data.unlocking_date) {
            data.unlocking_date = formatDateToYMD(data.unlocking_date);
        }
        console.log(data);
        try {
            const response = await dashboardService().updateLecture(id, data)
            if (response?.status === 200) {
                reset()
                toast.success('Edited Module')
                navigateBack()
            } else {
                const responseObj = response?.response?.data
                const firstKey = Object.keys(responseObj)[0];
                const message = responseObj[firstKey][0]
                console.log(message, 'msg')
                toast.error(message)
            }

        } catch (error) {
            console.log(error.message, 'error')
            toast(error.message)
        }
    };

    useEffect(() => {
        if (id) fetchData()
    }, [id])

    useEffect(() => {
        console.log(getValues(), 'values')
    }, [getValues])

    const disableUnlockDate = !isUpcomingDate(unlockingDateInServer )

    return (
        <div className=" w-full p-12">
            <BackNavSection navigateBack={navigateBack} />
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-8  max-w-3xl ">
                {/* Module Number */}
                <div>
                    <label className="block text-base font-medium text-gray-800 mb-2">Module Number / Order Number </label>
                    <input
                        type="number"
                        disabled
                        {...register('sort_order')}
                        className={`block w-full px-4 py-2 border-gray-300 rounded-md shadow-sm text-gray-700 focus:ring-indigo-500 focus:border-indigo-500 ${errors.sort_order ? 'border-red-500' : ''
                            }`}
                    />
                    {errors.sort_order && <p className="mt-2 text-sm text-red-600">{errors.sort_order.message}</p>}
                </div>

                {/* Title */}
                <div>
                    <label className="block text-base font-medium text-gray-800 mb-2">Title*</label>
                    <input
                        type="text"
                        {...register('title')}
                        className={`block w-full px-4 py-2 border-gray-300 rounded-md shadow-sm text-gray-700 focus:ring-indigo-500 focus:border-indigo-500 ${errors.title ? 'border-red-500' : ''
                            }`}
                    />
                    {errors.title && <p className="mt-2 text-sm text-red-600">{errors.title.message}</p>}
                </div>

                {/* Description */}
                <div>
                    <label className="block text-base font-medium text-gray-800 mb-2">Description</label>
                    <textarea
                        rows="4"
                        {...register('description')}
                        className="block w-full px-4 py-2 border-gray-300 rounded-md shadow-sm text-gray-700 focus:ring-indigo-500 focus:border-indigo-500"
                    />
                    {errors.description && <p className="mt-2 text-sm text-red-600">{errors.description.message}</p>}
                </div>

                {/* Video URL */}
                <div>
                    <label className="block text-base font-medium text-gray-800 mb-2">Video URL*</label>
                    <input
                        type="text"
                        {...register('video_url')}
                        className={`block w-full px-4 py-2 border-gray-300 rounded-md shadow-sm text-gray-700 focus:ring-indigo-500 focus:border-indigo-500 ${errors.video_url ? 'border-red-500' : ''
                            }`}
                    />
                    {errors.video_url && <p className="mt-2 text-sm text-red-600">{errors.video_url.message}</p>}
                </div>

                {/* Unlocking Date */}
                <div>
                    <label className="block text-base font-medium text-gray-800 mb-2">{disableUnlockDate ? 'Unlocked':"Unlocking"} Date</label>
                    <input
                    disabled={disableUnlockDate}
                        type="date"
                        min={new Date().toISOString().split('T')[0]}
                        {...register('unlocking_date')}
                        className="block w-full px-4 py-2 border-gray-300 rounded-md shadow-sm text-gray-700 focus:ring-indigo-500 focus:border-indigo-500"
                    />
                    {errors.unlocking_date && <p className="mt-2 text-sm text-red-600">{errors.unlocking_date.message}</p>}
                </div>

                {/* Grade Select Input */}
                <GradeSelectInput control={control} errors={errors} />

                {/* Submit Button */}
                <div>
                    <button
                        type="submit"
                        className="w-full py-3 px-6 bg-indigo-600 text-white font-medium rounded-md shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-200"
                    >
                        Submit
                    </button>
                </div>
            </form>

        </div>
    );
};

export default ModuleEditView;
