import React, { useEffect, useState } from 'react'
import QuestionsTable from './QuestionsTable'
import { questionBankService } from '../../service/questionBankService'
import MCQPreview from './MCQPreview'
import { toast } from 'react-toastify'


const MCQListing = ({ grade, lecture }) => {
    const [list, setList] = useState([])
    const [showPreview, setShowPreview] = useState(false)
    const [selectedId, setSelectedId] = useState(null)


    const onPreviewClick = (id) => {
        setSelectedId(id)
        setShowPreview(true)
    }

    const onDeleteClick = async (id) => {
        try {
            const response = await questionBankService().deleteMcq(id)
            if (response.status === 204) {
                toast.success('Deleted Successfully')
                fetchList()
            } else {
                toast.error(response?.message)
            }
        } catch (error) {
            toast.error(error.message)
        }

    }

    const fetchList = async () => {
        try {
            const response = await questionBankService().listMCQ(grade, lecture)
            if (response?.status === 200) {
                setList(response?.data?.results)
            }else{
                toast.error(response?.message)
            }
        } catch (error) {
            toast.error(error.message)
        }
    }

    useEffect(() => {
        fetchList()
    }, [])

    return (
        <>
            <QuestionsTable list={list} onPreviewClick={onPreviewClick} onDeleteClick={onDeleteClick} />
            {selectedId ? <MCQPreview isOpen={showPreview} handleClose={() => setShowPreview(false)} id={selectedId} /> : null}
        </>
    )
}

export default MCQListing