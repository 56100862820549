import React from 'react'

const McqQuestionWithSolution = ({data}) => {
    const optionLabels = ['A', 'B', 'C', 'D']

    if(data){
        return (
            <div className="px-6 py-2 rounded-lg shadow-md max-w-xl mx-auto mt-[20%] bg-white">
                <h2 className="text-xl font-semibold mb-4">{data?.question}</h2>
                <div className="grid grid-cols-2 gap-4">
                    {optionLabels.map((option, index) => (
                        <div key={option} className={`p-4 rounded-lg shadow-md ${data?.correct_option === option  ? 'bg-green-100' : 'bg-white'}  `}>
                            <label className="flex items-center space-x-3">
                                <span className="text-gray-700">{data[`option_${option.toLowerCase()}`]}</span>
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
    else{
        return <div>loading..</div>
    }
   
}
export default McqQuestionWithSolution