// ProgressBar.js
import React from 'react';

const ProgressBar = ({ completed, total,progress,showPercent=false }) => {
  let percentage = progress ? progress : Math.round((completed / total) * 100);
  if(Number.isNaN(percentage)){
    percentage = 0
  }
  return (
    <div className="flex items-center w-full">
      {showPercent ?<span className="text-gray-700 mr-2">{progress}%</span> :null}
      <div className="w-full bg-white rounded-full h-4 mr-2">
        <div
          className="bg-gold h-4 rounded-full"
          style={{ width: ` ${percentage}%` }}
        ></div>
      </div>
      {progress === null || progress === 0 ? null : <span className="text-gray-700">{completed}/{total}</span>}
    </div>
  );
};

export default ProgressBar;
