import React, { useEffect, useState } from 'react';
import ModalInput from '../../../components/modal/ModalInput';
import PrimaryButton from '../../../components/button/PrimaryButton';
import { questionBankService } from '../../../service/questionBankService';
import { toast } from 'react-toastify';

import SimulationTableSetup from './SimulationTableSetup';
import { Controller, useForm } from 'react-hook-form';
import { simulationSetupSchema } from '../../../utils/schema';
import { yupResolver } from '@hookform/resolvers/yup';
import TooltipError from '../../../components/tooltipError/TooltipError';
import { useDispatch } from 'react-redux';
import { createDummySimulation } from '../../../redux/exerciseTestSlice';
import { useNavigate } from 'react-router-dom';

const CreateSimulation = ({ lectureId, gradeId }) => {
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [columnCount, setColumnCount] = useState(2);
  const [selectedCell, setSelectedCell] = useState(null);
  const [isModalOpen, setisModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [answers, setAnswers] = useState({});

  const { register, handleSubmit, watch, control, formState: { errors } } = useForm({
    resolver: yupResolver(simulationSetupSchema),
    defaultValues: {
      columnCount: 1,
      columns: Array(1).fill({ type: '' })
    }
  });

  const handleRowCountChange = (e) => {
    const count = parseInt(e.target.value);
    if (!isNaN(count)) {
      setRowCount(count);
      generateRows(count, columnCount);
    }
  };

  const handleColumnCountChange = (e) => {
    const count = parseInt(e.target.value);
    if (!isNaN(count) && count >= 2) {
      setColumnCount(count);
      generateRows(rowCount, count);
    }
  };

  const generateRows = (rowCount, columnCount) => {
    const newRows = [];
    for (let i = 0; i < rowCount; i++) {
      const row = {};
      for (let j = 0; j < columnCount; j++) {
        const columnType = String.fromCharCode(97 + j); // 'a', 'b', 'c', etc.
        row[columnType] = {
          rowIndex: i,
          cell: `${columnType}${i + 1}`,
          columnType,
          value: '',
          isQuestion: false,
          questionType: j === 0 ? 'text' : 'number', // First column is text, others are number
          options: [{ value: '' }, { value: '' }, { value: '' }, { value: '' }],
        };
      }
      newRows.push(row);
    }
    setRows(newRows);
  };

  const handleCellClick = (cell) => {
    setSelectedCell(cell);
  };



  const handleCloseModal = () => {
    setSelectedCell(null);
    setisModalOpen(false);
  };

  const handleSaveModal = (data) => {
    console.log(data, 'data in save');
    let rowsArr = [...rows];
    let rowObj = rowsArr[selectedCell.rowIndex];
    let columnObj = rowObj[selectedCell.columnType];
    let answerObj = {...answers}
    console.log(columnObj, 'columnObj');
    columnObj.value = data?.cellValue;
    columnObj.isQuestion = data?.isQuestion;

    if (data.isQuestion) {
      answerObj[columnObj.cell] = data?.cellValue
    }
    if (data.isQuestion && selectedCell.questionType === 'text') {
      columnObj.options = data.options;
      columnObj.value =  data.options[data?.correctOption].value
      columnObj.correctOption = data?.correctOption;
      answerObj[columnObj.cell] = data?.correctOption
    }
    setRows(rowsArr);
    setAnswers(answerObj)
    setisModalOpen(false);
    setSelectedCell(null);

  };

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(()=>{
console.log(answers,'answer satte')
  },[answers])


  const onSubmit = async () => {
    console.log(rows);
    let filledRows = []
    rows.forEach((row, index) => {
      console.log(row,'row',index)
      const eachRowAsArray = Object.entries(row)
      let hasEmptyCells = []
       eachRowAsArray.map(i => console.log(i[1].value,'i'))
      const isEmptyRow = eachRowAsArray.every(i => i[1].value === "")
      console.log(isEmptyRow,'hasEmptyRow',index)
      if (!isEmptyRow) {
        filledRows.push(row)
      }
    });
    console.log(filledRows, 'filledRows')


    const formData = {
      grade: gradeId,
      lecture: lectureId,
      question: content,
      cellData: filledRows,
      answers: answers
    };

    let answersObject = {
      a1: '2024-07-04',
      b1: 'Add: Receipts column undercast',
      d1: 200,
      c2: 1000

    }
    try {
      const response = await questionBankService().createSimulation(formData);
      if (response?.status === 201) {
        toast.success('Simulation created !!');
      } else {
        const responseObj = response?.response?.data;
        const firstKey = Object.keys(responseObj)[0];
        const message = responseObj[firstKey][0];
        console.log(message, 'msg');
        toast(message);
      }
    } catch (error) {
      console.log(error.message, 'error');
      toast('Something went wrong try again later');
    }
  };

  const [content, setContent] = useState('');

  const handleEditorChange = (value) => {
    setContent(value);
  };

  const columnTypes = watch('columns')

  const getQuestionType = (colType) => {
    if (colType === 'Particular') {
      return 'text'
    } else if (colType === 'Date') {
      return 'date'
    } else {
      return 'number'
    }
  }

  const submitSetup = (data) => {
    // console.log(data)
    //An object of rows object
    let tempRows = [...rows]
    tempRows.forEach((row, rowIndex) => {
      let rowAsArray = Object.entries(tempRows[rowIndex])
      rowAsArray.forEach((elementObj, index) => {
        tempRows[rowIndex][elementObj[0]].columnName = columnTypes[index].type
        tempRows[rowIndex][elementObj[0]].questionType = getQuestionType(columnTypes[index].type)
      });
    })



    console.log(JSON.stringify(tempRows), 'temp rows')

    setRows(tempRows)
    setShowTable(true)
  }
  useEffect(() => {
    if (selectedCell) {
      setisModalOpen(true);
    }
  }, [selectedCell]);

  useEffect(() => {
    console.log(rows, 'rows')
    console.log(columnCount, 'columnCount')
  }, [rows]);

  console.log(content, 'content')

  if (showTable) {
    return <SimulationTableSetup rows={rows} columnCount={columnCount} isLoading={isLoading} handleCloseModal={handleCloseModal} content={content} handleCellClick={handleCellClick} isModalOpen={isModalOpen} setisModalOpen={setisModalOpen} handleEditorChange={handleEditorChange} handleSaveModal={handleSaveModal} onSubmit={onSubmit} selectedCell={selectedCell} />
  }
  return (
    <form onSubmit={handleSubmit(submitSetup)}>
      <div className="flex justify-center gap-4 items-center p-4 bg-primary">

        <div className="w-1/4">
          <label htmlFor="rowCount" className="block text-sm font-medium text-gray-700">Enter row count:</label>
          <input
            type="number"
            id="rowCount"
            name="rowCount"
            min="0"
            value={rowCount}
            onChange={handleRowCountChange}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="w-1/4">
          <label htmlFor="columnCount" className="block text-sm font-medium text-gray-700">Enter column count (min 2):</label>
          <input
            type="number"
            id="columnCount"
            name="columnCount"
            min="2"
            value={columnCount}
            onChange={handleColumnCountChange}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
      </div>
      {rows.length > 0 ?
        <div className='flex items-center gap-2  justify-center my-12'>
          {Array.from({ length: columnCount }, (_, index) => (
            <div key={index} className='relative'>
              <label className="block text-sm font-medium text-gray-700">Column {index + 1}</label>
              <Controller
                name={`columns[${index}].type`}
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <select
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    {...field}>
                    <option value="">Select type</option>
                    <option value="Particular">Particular</option>
                    <option value="Amount">Amount</option>
                    <option value="Date">Date</option>
                  </select>
                )}
              />
              {errors.columns?.[index]?.type && <TooltipError message={errors.columns[index].type.message} />}
            </div>
          ))}
        </div>
        : null
      }
      <div className="w-full flex justify-center ">
        <PrimaryButton type="submit">Proceed</PrimaryButton>

      </div>


    </form>
  );
};

export default CreateSimulation;
