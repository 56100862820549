import React, { useEffect, useState } from 'react'
import Loader from '../../../components/loader/Loader'

const SimulationWithSolution = ({ data }) => {

    const [header, setHeader] = useState([])

    const getCellStyle = (cellItem) => {
        if (cellItem?.isQuestion) {
            return { borderWidth: '2px', borderColor: 'orange ' }
        } else {
            return { borderWidth: '1px', borderColor: 'gray ' }

        }
    }


    useEffect(() => {
        if (data?.length > 0) {
            const firstObject = data[0]
            setHeader(Object.values(firstObject).map(item => item.columnName))
        }
    }, [data])
    if(!data) return <Loader />
    return (
        <div>
            <div style={{ borderColor: 'wheat' }} className="grid grid-cols-2 gap-4 mt-8">
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            {header.map(i => <th key={i} className='min-w-52  p-2 border border-gray-300'>{i?.toUpperCase()}</th>)}

                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((row, rowIndex) => {
                            const rowAsArray = Object.entries(row)
                            // console.log(rowAsArray[1], 'row as array')
                            return <tr key={rowIndex}>
                                <td className='border border-gray-300'>{rowIndex + 1}</td>
                                {rowAsArray.map((col, colIndex) => {
                                    // console.log(col[1], 'col', rowIndex)
                                    const cellItem = col[1]
                                    return (
                                        <td className={` relative`} style={getCellStyle(cellItem)} >{cellItem?.value} </td>
                                    )
                                })}
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default SimulationWithSolution