import React, { useEffect, useState } from 'react';
import { CheckBoxOutlineBlank, ChevronLeft, ChevronRight, Delete } from '@mui/icons-material';
import PrimaryButton from '../../../components/button/PrimaryButton';
import CheckBox from '@mui/icons-material/CheckBox';
import { useNavigate } from 'react-router-dom';
import { dashboardService } from '../../../service/dashboardService';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setTestParams } from '../../../redux/practiceTestSlice';
import WarningModal from '../../../components/modal/WarningModal';

function Practicetest() {
    const [selected, setSelected] = useState([]);
    const [selectedQuestionType, setSelectedQuestionType] = useState('MCQ');
    const [modules, setModules] = useState([]);
    const [maxQuestions, setMaxQuestions] = useState(0);
    const [questionLength, setQuestionLength] = useState(0);
    const [showWarning, setShowWarning] = useState(false);


    const navigate = useNavigate();
    const dispatch = useDispatch()

    const handleSelection = (item) => {
        if (selected.includes(item.id)) {
            setSelected((prev) => prev.filter((i) => i !== item.id));
        } else {
            setSelected((prev) => [...prev, item.id]);
        }
    };

    const calculateMaxQuestions = () => {
        const total = selected.reduce((sum, id) => {
            const module = modules.find((m) => m.id === id);
            if (!module) return sum;

            // Add the total for the selected question type
            switch (selectedQuestionType) {
                case 'MCQ':
                    return sum + (module.total_mcq || 0);
                case 'LONG_ANSWER':
                    return sum + (module.total_long_answer || 0);
                case 'SIMULATION':
                    return sum + (module.total_simulation || 0);
                default:
                    return sum;
            }
        }, 0);

        setMaxQuestions(total);
        if (questionLength > total) {
            setQuestionLength(total); // Reset to max if current exceeds total
            toast.warning('Number of questions exceeds available total. Adjusted to the maximum.');
        }
    };

    const fetchModules = async () => {
        try {
            const response = await dashboardService().getLectures();
            if (response?.status === 200) {
                setModules(response?.data?.results);
            } else {
                toast.error('Something went wrong');
            }
            console.log(response, 'response');
        } catch (error) {
            console.error('Error fetching modules:', error);
        }
    };

    useEffect(() => {
        fetchModules();
    }, []);

    useEffect(() => {
        calculateMaxQuestions();
    }, [selected, selectedQuestionType]);

    return (
        <div className="min-h-screen bg-gray-100 p-4">
            <div className="container mx-auto">
                <div className="w-1/4 mb-8">
                    <label htmlFor="question_type" className="mb-2 block text-sm font-medium text-gray-700">
                        Question Type
                    </label>
                    <select
                        value={selectedQuestionType}
                        onChange={(e) => setSelectedQuestionType(e.target.value)}
                        id="question_type"
                        name="question_type"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                        <option value="MCQ">Multiple Choice</option>
                        <option value="LONG_ANSWER">Long Answer</option>
                        <option value="SIMULATION">Simulation Table</option>
                    </select>
                </div>

                <label
                    className="mb-2 block text-sm font-medium text-gray-700"
                    htmlFor="select_module"
                >
                    Select modules out of which questions should be generated
                </label>
                <div className="border w-1/2 p-4 rounded bg-white">
                    {modules.filter(i => i.is_unlocked).map((module) => (
                        <div
                            key={module.id}
                            onClick={() => handleSelection(module)}
                            className="flex cursor-pointer items-center gap-2 mb-2"
                        >
                            {selected.includes(module.id) ? <CheckBox /> : <CheckBoxOutlineBlank />}
                            <p className="text-sm">{module.title}</p>
                        </div>
                    ))}
                </div>

                <div className="w-1/4 mt-8 mb-8">
                    <label
                        className="block mb-2 text-sm font-medium text-gray-700"
                        htmlFor="question_length"
                    >
                        Number of questions to be generated
                    </label>
                    <input
                        className="w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        id="question_length"
                        name="question_length"
                        type="number"
                        value={questionLength}
                        onChange={(e) => {
                            const value = Math.min(Number(e.target.value), maxQuestions);
                            if (value < 0) return; // Prevent negative numbers
                            setQuestionLength(value);
                        }}
                    />
                    <p className="text-sm text-gray-500 mt-2">
                        Max questions allowed: <span className="font-bold">{maxQuestions}</span>
                    </p>
                </div>
            </div>
            <PrimaryButton
                onClick={() => setShowWarning(true)}
                disabled={selected.length === 0 || questionLength <= 0 || maxQuestions === 0}
            >
                Start
            </PrimaryButton>
            <WarningModal isOpen={showWarning} onClose={() => setShowWarning(false)} onProceed={() => {
                const data = {
                    lecture_ids: selected,
                    question_type: selectedQuestionType,
                    questions_count: questionLength,
                }
                dispatch(setTestParams(data))
                navigate('/attend-practice')
            }} />
        </div>
    );
}

export default Practicetest;
