import React from 'react'
import { longAnswerSchema } from '../../utils/schema';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { questionBankService } from '../../service/questionBankService';
import PrimaryButton from '../../components/button/PrimaryButton';

const CreateLongAnswer = ({ lectureId, gradeId }) => {
    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(longAnswerSchema),
        defaultValues: {
            question: '',
            answer: ''
        },
    });

    const onSubmit = async (data) => {
        console.log(data);

        const formData = {
            grade: gradeId,
            lecture: lectureId,
            question: data.question,
            answer: data.answer
        }
        try {
            const response = await questionBankService().createLongAnswer(formData)
            if (response?.status === 201) {
                reset()
                toast.success('Question created !!')

            } else {
                const responseObj = response?.response?.data
                const firstKey = Object.keys(responseObj)[0];
                const message = responseObj[firstKey][0]
                console.log(message, 'msg')
                toast(message)
            }

        } catch (error) {
            console.log(error.message, 'error')
            toast('Something went wrong try again later')
        }
    };
    return (
        <div className=" mt-10 p-5 border rounded-lg shadow-lg bg-primary">
            <h2 className="text-2xl font-bold mb-5"></h2>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Question</label>
                    <Controller
                        name="question"
                        control={control}
                        render={({ field }) => (
                            <input
                                {...field}
                                type="text"
                                className={`bg-secondary w-full p-2 border ${errors.question ? 'border-red-500' : 'border-gray-300'} rounded`}
                            />
                        )}
                    />
                    {errors.question && <p className="text-red-500 text-sm mt-1">{errors.question.message}</p>}
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Answer</label>
                    <Controller
                        name="answer"
                        control={control}
                        render={({ field }) => (
                            <input
                                {...field}
                                type="text"
                                className={`bg-secondary w-full p-2 border ${errors.answer ? 'border-red-500' : 'border-gray-300'} rounded`}
                            />
                        )}
                    />
                    {errors.answer && <p className="text-red-500 text-sm mt-1">{errors.answer.message}</p>}
                </div>

                <PrimaryButton type="submit" >
                    Submit
                </PrimaryButton>
            </form>
        </div>

    )
}

export default CreateLongAnswer