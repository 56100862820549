import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import Logo from '../components/logo/Logo'
import { AccountCircleOutlined } from '@mui/icons-material'
import { logout } from '../redux/authSlice'

const LandingLayout = ({ children }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    const handleProfileClick = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleLogout = () => {
        dispatch(logout())
        navigate('/')
    };
    return (
        <div className="min-h-screen flex flex-col font-playfair">
            <nav className="bg-primary text-white p-2 px-8 flex justify-between items-center relative">
                <Logo onClick={() => navigate('/')} />
                <div className="flex items-center gap-x-12">
                    <div className="flex space-x-4">
                        <a href="#about" className="hover:underline text-navyBlue text-[16px] font-playfair">About Us</a>
                        <a href="#pricing" className="hover:underline text-navyBlue text-[16px] font-playfair">Gifts</a>
                        <a href="#contact" className="hover:underline text-navyBlue text-[16px] font-playfair">Contact Us</a>
                    </div>

                    {isAuthenticated ? <button onClick={handleProfileClick} className="focus:outline-none w-12 h-12">
                        <AccountCircleOutlined style={{ fontSize: '40px', color: '#030351' }} />
                    </button> : <Link className="px-4 py-1 bg-highlight rounded text-[14px] hover:bg-hoverColor" to="/login" >Login</Link>}
                    {dropdownOpen && (
                        <div className="absolute top-14 right-0 mt-2 w-48 bg-white text-black rounded shadow-lg">
                             <button
                            onClick={()=> navigate('/dashboard')}
                            className="block px-4 py-2 hover:bg-gray-100 w-full text-left"
                        >
                            Dashboard
                        </button>
                            <button
                                onClick={handleLogout}
                                className="block px-4 py-2 hover:bg-gray-100 w-full text-left"
                            >
                                Logout
                            </button>
                        </div>
                    )}
                </div>
            </nav>
            {children}
        </div>
    )
}

export default LandingLayout