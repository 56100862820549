import axios from 'axios';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import AdminDashboard from './adminDashboard/AdminDashboard';
import StudentOverview from './studentDashboard/StudentOverview';

const Dashboard = () => {
  const user = useSelector((state) => state.auth.user);
 
  switch (user.role) {
    case 'admin':
      return <AdminDashboard />
      case 'student':
        return <StudentOverview />
    default:
      return <StudentOverview />

  }



}

export default Dashboard