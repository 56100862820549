import axios from 'axios';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { AutoStories, BarChart, FlagCircle, Pool } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { dashboardService } from '../../../service/dashboardService';
import Breadcrumb from '../../../components/breadCrumbs/BreadCrumbs';

const StudentOverview = () => {

  const fetchQoutes = async () => {
    const response = await axios("https://api.api-ninjas.com/v1/quotes?category=happiness")
    console.log(response, 'res')

  }

  const fetchActivePlanDetails = async () => {

    const response = await dashboardService().getActiveSubscriptionDetail()
    console.log(response, 'res')
  }

  useEffect(() => {
    // fetchQoutes()
    fetchActivePlanDetails()
  }, [])

  const navigate = useNavigate()

  const navItems = [
    { title: 'Accounting', path: '/study', icon:  <AutoStories style={{ fontSize:26,color: '#030351' }} /> },
    { title: 'Life Skills', path: '/study', icon: <Pool style={{fontSize:26, color: '#030351' }} /> },
    { title: 'Challenges', path: '/study', icon:  <FlagCircle style={{ fontSize:26,color: '#030351' }} /> },
    ]
  
  return (
    <div>
      <div className='bg-white rounded p-4 mb-12'>
        <h3 className='text-[blue] text-[16px] mb-2'>Welcome , Student</h3>
        <h2 className="text-navyBlue font-bold text-[52px] mb-12">Qoute for the day</h2>
        <div className="">
          <p className='text-lg '>“The way I see it, if you want the rainbow, you gotta put up with the rain!” </p>
        </div>

      </div>
      <div className='my-4 rounded flex items-center justify-between'>
        {navItems.map(i => <div className='cursor-pointer bg-white  border-1 border-[#ccc] rounded p-4 flex flex-col gap-2 justify-center items-center w-[32%] h-[200px] hover:scale-105' key={i.title} onClick={() => navigate(i.path)}>
          {i.icon}
          <p className='text-xl font-bold  text-highlight '>{i.title}</p>
        </div>)}
      </div>
  
    </div>
  )
}

export default StudentOverview