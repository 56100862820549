import * as yup from 'yup';

const signupSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  surname: yup.string().required('Surname is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  whatsapp: yup.string().required('WhatsApp No. is required'),
  school: yup.string().required('School is required'),
  grade: yup.string().required('Grade/Class is required'),
  gender: yup.string().required('Gender is required'),
  referal: yup.string(),
  password: yup
  .string()
  .required('Password Required')
  // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, 'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character'),
});

const loginSchema = yup.object().shape({
  email: yup.string().email('Invalid email format').required('Email is required'),
  password: yup.string().required('Password is required').min(6, 'Password must be at least 6 characters long'),
});

const tableTestSchema = yup.object().shape({
  rows: yup.array().of(
    yup.object().shape({
      a: yup.string().required('Field A is required'),
      b: yup.number().required('Field B is required'),
      isQuestion: yup.boolean().default(false),
      options: yup
        .array()
        .when('isQuestion', {
          is: true,
          then: () => yup
            .array()
            .min(4, 'Options array must contain at least 4 objects')
            .of(
              yup.object().shape({
                id: yup.number().required(),
                value: yup.string().required(),
              })
            )
            .required('Options array is required when isQuestion is true'),
          otherwise: () => yup.array().notRequired(),
        }),
    })
  ),
});


const cellInputschema = yup.object().shape({
  cellValue: yup.string().notRequired(),
  questionType:yup.string(),
  isQuestion: yup.boolean().required(),
  options: yup.array().of(
      yup.object().shape({
          value: yup.string().required('Option value is required')
      })
  ).min(4, 'Exactly 4 options are required').max(4, 'Exactly 4 options are required')
      .when(['isQuestion', 'questionType'], {
          is: (isQuestion, questionType) => isQuestion === true && questionType === "text",
          then: () => yup.array().of(
              yup.object().shape({
                  value: yup.string().required('Option value is required')
              })
          ).min(4, 'Exactly 4 options are required').max(4, 'Exactly 4 options are required'),
          otherwise: () => yup.array().of(
              yup.object().shape({
                  value: yup.string()
              })
          )
      }),
  correctOption: yup.number()
      .nullable()
      .when(['isQuestion', 'questionType'], {
        is: (isQuestion, questionType) => isQuestion === true && questionType === "text",
        then: ()=>yup.number().min(0, 'Select a valid option').max(3, 'Select a valid option').required('Correct option is required'),
        otherwise:()=> yup.number().nullable(true)
      }),
});

const mcqSchema =yup.object().shape({
  question: yup.string().required('Question is required'),
  options: yup.array().of(
    yup.object().shape({
      value: yup.string().required('Option value is required')
    })
  ).min(4, 'Exactly 4 options are required').max(4, 'Exactly 4 options are required'),
  explanation:yup.string(),
  correctOption: yup.number().min(0, 'Select a valid option').max(3, 'Select a valid option').required('Correct option is required'),
});


const simulationSetupSchema = yup.object().shape({
  columnCount: yup.number().required('Column count is required').min(1, 'Must be at least 1'),
  columns: yup.array().of(
    yup.object().shape({
      type: yup.string().oneOf(['Particular', 'Amount', 'Date'],"should select a column type").required('Type is required')
    })
  )
});

const longAnswerSchema = yup.object().shape({
  question:yup.string().required('Question is required').min(5, 'Must be at least 5 char long '),
  answer:yup.string().required('Answer is required')

})

const answersSchema = yup.object().shape({
  answer1:yup.string().required('Answer is required')

})

export {signupSchema,loginSchema,tableTestSchema,cellInputschema,mcqSchema,simulationSetupSchema,longAnswerSchema,answersSchema}