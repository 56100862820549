

import { apiAuthenticatedRequest, apiRequest } from '../api/apiRequest'

export const practiceTestService = () => {
  return {
   
    startPracticeTest: (data, headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'POST',
        url: `exams/practice/start/`,
        headers,
        data,
      })
    },
    getPracticeTestQuestions: (headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'GET',
        url: `exams/practice/questions`,
        headers
      })
    },
    submitAnswer: (id,data,testType, headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'POST',
        url: `/exams/exercise/${testType}/${id}/submit/`,
        data,
        headers
      })
    },
    getPracticeTestSummary: (headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'GET',
        url: `/exams/practice/summary/`,
        headers
      })
    },
    submitAnswer: (id,data, headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'POST',
        url: `/exams/practice/${id}/submit/`,
        data,
        headers
      })
    },
    getPracticeTestSolutionById: (id,headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'GET',
        url: `/exams/practice/ques/${id}/solution/`,
        headers
      })
    },
 
    
    
  }
}
