import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CircularProgress, Modal } from '@mui/material'
import PrimaryButton from '../../../components/button/PrimaryButton'
import { testService } from '../../../service/testService'
import { practiceTestService } from '../../../service/practiceTestService'
import CircularProgressWithLabel from '../../../components/circularProgress/CircularProgressWithLabel'

const PracticetestResultModal = ({ isOpen, handleClose, handleShowSolution }) => {
    const [data, setData] = useState(null)

    // const {id} =  useParams()
    const navigate = useNavigate()

    const fetchData = async () => {
        try {
            const response = await practiceTestService().getPracticeTestSummary()
            console.log(response, 'res')
            setData(response?.data)
        } catch (error) {
            console.log(error.message, 'error')
        }
    }

    const navigateBack = () => {
        navigate('/modules')
    }

    useEffect(() => {
        if (isOpen) {
            fetchData()
        }
    }, [isOpen])
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    return (
        <Modal open={isOpen} onClose={handleClose}>
            <div style={style} className="modal-content w-full relative !pt-[40px]">
                <h2 className="text-xl font-bold mb-4">Result</h2>
                <div className="flex">
                    <h2>Time Taken : {data?.time_taken}</h2>
                </div>
                <div className="flex mb-4  justify-between ">
                    <div className=' p-4'>
                        {/* <h3 className='text-xl font-bold'>Attended</h3> */}
                        {/* <CircularProgressWithLabel value={data?.attempted_questions}/> */}
                        <CircularProgressWithLabel color={'tomato'} value={data?.attempted_questions} label="Attended" size={data?.total_questions} />
                    </div>
                    <div className=' p-4'>
                        <CircularProgressWithLabel value={data?.correct_answers} label="Correct" size={data?.total_questions} />
                    </div>
                </div>
                <div className="flex items-center gap-2">
                    <PrimaryButton className='!bg-green-500 !hover:bg-green-600' onClick={handleShowSolution}>Show Solution</PrimaryButton>
                    <PrimaryButton onClick={handleClose}>Exit</PrimaryButton>
                </div>
            </div>
        </Modal>
    )
}

export default PracticetestResultModal