import { Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { questionBankService } from '../../service/questionBankService';
import { toast } from 'react-toastify';
import { CalendarViewMonthOutlined, List } from '@mui/icons-material';
import DOMPurify from 'dompurify';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vw',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow:'scroll',
  height:'60vh'
};

const SimluationPreview = ({ isOpen, handleClose, id }) => {
  const [data, setData] = useState(null)
  const [celldata, setCellData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [header, setHeader] = useState(null)

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await questionBankService().getSimulationDetails(id)
      setLoading(false)
      console.log(response, 'res')
      if (response?.status === 200) {
        setData(response?.data)
        setCellData(response?.data?.cellData)
      } else {
        toast.error(response?.message)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    if (id) fetchData()
  }, [id])

  useEffect(() => {
    if (celldata?.length > 0) {
      const firstObject = celldata[0]
      console.log(firstObject, 'firstObject')
      setHeader(Object.values(firstObject)?.map(item => item.columnName))
    }

  }, [celldata])


  useEffect(() => {
    console.log(celldata, 'celldata')
  }, [celldata])
  console.log(header, 'header')

  const getCellStyle = (cellItem) => {
    if (cellItem?.isQuestion) {
      return { borderWidth: '2px', borderColor: 'green ' }
    } else {
      return { borderWidth: '1px', borderColor: 'gray ' }

    }
  }

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <div style={style} className="modal-content w-full relative !pt-[40px] overflow-y">
        {loading ? <div>Loading....</div> : <div className="p-6 text-left bg-white">
          <div className='mb-8' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data?.question) }} />
          <table>
            <thead>
              <tr>
                <th></th>
                {header?.map(i => <th key={i} className='min-w-32  p-2 border border-gray-300'>{i?.toUpperCase()}</th>)}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {celldata?.map((row, rowIndex) => {
                const rowAsArray = Object.entries(row)
                // console.log(rowAsArray[1], 'row as array')
                return <tr key={rowIndex}>
                  <td className='border border-gray-300'>{rowIndex + 1}</td>
                  {rowAsArray?.map((col, colIndex) => {
                    // console.log(col[1], 'col', rowIndex)
                    const cellItem = col[1]
                    return (
                      <td className={` relative`} style={getCellStyle(cellItem)} >{cellItem?.value}
                        {cellItem?.isQuestion && <div className="w-4 h-4 rounded bg-gray-400 absolute top-2 right-2 grid place-content-center">
                          {cellItem.isQuestion && cellItem.questionType === 'date' ? <CalendarViewMonthOutlined style={{ color: '#fff', fontSize: '12px' }} /> : null}
                          {cellItem.isQuestion && cellItem.questionType === 'text' ? <List style={{ color: '#fff', fontSize: '12px' }} /> : null}
                          {cellItem.isQuestion && cellItem.questionType === 'number' ? <p className='text-white font-bold text-[8px]'>123</p> : null}
                        </div>}
                      </td>
                    )
                  })}
                </tr>
              })}
            </tbody>
          </table>
        </div>}

      </div>
    </Modal>
  );
};

export default SimluationPreview;
