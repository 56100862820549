import React, { useEffect, useState } from 'react'
import ProgressBar from '../../../components/progressBar/ProgressBar'
import { dashboardService } from '../../../service/dashboardService'
import { toast } from 'react-toastify'
import ProgressAnalytics from './ProgressAnalytics'

const Analytics = () => {
    const [lectureData, setLectureData] = useState([])
    const [overallAnalyticData, setOverallAnalyticData] = useState(null)

    const fetchAnalytics = async () => {

        try {
            const response = await dashboardService().getAnalyticData()
            if (response.status === 200) {
                setOverallAnalyticData(response?.data)
                fetchAnalyticsByLecture()
            } else {
                toast('Something went wrong while fetching lecture wise data')
            }
        } catch (error) {
            toast(error.message)
        }
    }

    const fetchAnalyticsByLecture = async () => {
        try {
            const response = await dashboardService().getAnalyticDataByLectures()
            if (response.status === 200) {
                setLectureData(response?.data?.analytics)
            } else {
                toast('Something went wrong while fetching lecture wise data')
            }
        } catch (error) {
            toast(error.message)

        }
    }



    useEffect(() => {
        fetchAnalytics()
    }, [])
console.log(overallAnalyticData,'overallAnalyticData')
    const data = {
        practiceTest: {
            mcq: { attended: 20, correct: 30 },
            simulation: { attended: 15, correct: 25 },
        },
        unitTest: {
            mcq: { attended: 25, correct: 20 },
            simulation: { attended: 18, correct: 28 },
        },
    };
    return (
        <div className="">
            <ProgressAnalytics data={overallAnalyticData} />
            <div className="bg-white rounded-lg shadow-md p-6 mt-4 w-full">
                {lectureData?.map((item) => (
                    <div
                        key={item?.id}
                        className="flex items-center bg-gray-50 border border-gray-200 p-4 rounded-lg mb-4 gap-4"
                    >
                        {/* Lecture Title */}
                        <p className="flex-1 text-lg font-semibold text-gray-800">{item?.title}</p>

                        {/* MCQ Section */}
                        <div className="flex-1">
                            <p className="text-center font-medium text-blue-600 mb-2">MCQ</p>
                            <div className="space-y-2">
                                {/* Attempted */}
                                <div className="flex items-center gap-4">
                                    <p className="w-1/4 text-sm font-medium text-gray-700">Attempted</p>
                                    <ProgressBar progress={parseInt(item?.mcq?.practice?.attempted, 10)} />
                                    <p className="text-sm font-semibold text-amber-800">
                                        {item?.mcq?.practice?.attempted}
                                    </p>
                                </div>
                                {/* Correct */}
                                <div className="flex items-center gap-4">
                                    <p className="w-1/4 text-sm font-medium text-gray-700">Correct</p>
                                    <ProgressBar progress={parseInt(item?.mcq?.practice?.correct, 10)} />
                                    <p className="text-sm font-semibold text-green-800">
                                        {item?.mcq?.practice?.correct}
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* Simulation Section */}
                        <div className="flex-1">
                            <p className="text-center font-medium text-blue-600 mb-2">Simulation</p>
                            <div className="space-y-2">
                                {/* Attempted */}
                                <div className="flex items-center gap-4">
                                    <p className="w-1/4 text-sm font-medium text-gray-700">Attempted</p>
                                    <ProgressBar progress={parseInt(item?.simulation?.practice?.attempted, 10)} />
                                    <p className="text-sm font-semibold text-amber-800">
                                        {item?.simulation?.practice?.attempted}
                                    </p>
                                </div>
                                {/* Correct */}
                                <div className="flex items-center gap-4">
                                    <p className="w-1/4 text-sm font-medium text-gray-700">Correct</p>
                                    <ProgressBar progress={parseInt(item?.simulation?.practice?.correct, 10)} />
                                    <p className="text-sm font-semibold text-green-800">
                                        {item?.simulation?.practice?.correct}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Analytics