import React from 'react'
import Header from '../components/header/Header'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import DashboardIcon from '@mui/icons-material/Dashboard';
import QuizIcon from '@mui/icons-material/Quiz';
import { AccountCircle, Analytics, Article, Book, LocalLibrary, Payment, People, Person, Settings } from '@mui/icons-material';
import Breadcrumbs from '../components/breadCrumbs/BreadCrumbs';

const DashboardLayout = ({ children }) => {
  const user = useSelector((state) => state.auth.user);
  const location = useLocation()
  const renderNavItems = () => {
    switch (user.role) {
      case 'admin':
        return (
          <nav className='flex flex-col gap-y-4 pt-4'>
            <NavLink
              to="/dashboard"
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}
              className={({ isActive }) =>
                isActive ? 'text-lg bg-gray-300' : 'text-lg hover:bg-gray-200'
              }
            >
              <DashboardIcon style={{ fontSize: '40px' }} />
              <p className='text-[12px] '>Overall</p>

            </NavLink>
            <NavLink
              to="/staffs"
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}

              className={({ isActive }) =>
                isActive ? 'text-lg bg-gray-300' : 'text-lg hover:bg-gray-200'
              }
            >
              <AccountCircle style={{ fontSize: '40px' }} />
              <p className='text-[12px] '>Staffs</p>


            </NavLink>
            <NavLink
              to="/students-listing"
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}

              className={({ isActive }) =>
                isActive ? 'text-lg bg-gray-300' : 'text-lg hover:bg-gray-200'
              }
            >
              <People style={{ fontSize: '40px' }} />

              <p className='text-[12px] '>Students</p>


            </NavLink>
            <NavLink
              to="/modules"
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}

              className={({ isActive }) =>
                isActive ? 'text-lg bg-gray-300' : 'text-lg hover:bg-gray-200'
              }
            >
              <Book style={{ fontSize: '40px' }} />

              <p className='text-[12px] '>Modules</p>


            </NavLink>
            <NavLink
              to="/question-bank"
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}
              className={({ isActive }) =>
                isActive ? 'text-lg bg-gray-300' : 'text-lg hover:bg-gray-200'
              }
            >
              <Article style={{ fontSize: '40px' }} />
              <p className='text-[12px] '>Question Bank</p>
            </NavLink>
          </nav>
        );
      case 'staff':
        return (
          <nav className='flex flex-col gap-4'>
            <NavLink
              to="/dashboard"
              className={({ isActive }) =>
                isActive ? 'text-lg bg-gray-300' : 'text-lg hover:bg-gray-200'
              }
            >
              Overall
            </NavLink>
            <NavLink
              to="/students-listing"
              className={({ isActive }) =>
                isActive ? 'text-lg bg-gray-300' : 'text-lg hover:bg-gray-200'
              }
            >
              Students
            </NavLink>
            <NavLink
              to="/question-bank"
              className={({ isActive }) =>
                isActive ? 'text-lg bg-gray-300' : 'text-lg hover:bg-gray-200'
              }
            >
              Question Bank
            </NavLink>
          </nav>
        );
      case 'student':
        return (
          <nav className='flex flex-col gap-y-4 pt-4'>
            <NavLink
              to="/dashboard"
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}
              className={({ isActive }) =>
                isActive ? ' text-white' : 'text-gray-400 hover:bg-gray-200'
              }
            >
              <DashboardIcon style={{ fontSize: '40px' }} />
              <p className='text-[12px] '>Dashboard</p>
            </NavLink>
            <NavLink
              to="/analytics"
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}
              className={({ isActive }) =>
                isActive ? ' text-white' : 'text-gray-400 hover:bg-gray-200'
              }
            >
              <Analytics style={{ fontSize: '40px' }} />
              <p className='text-[12px] '>Analytics</p>
            </NavLink>
            <NavLink
              to="/unit-test"
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}
              className={({ isActive }) =>
                isActive ? ' text-white' : 'text-gray-500 hover:bg-gray-200'
              }
            >
              <QuizIcon style={{ fontSize: '40px' }} />
              <p className='text-[12px] '>Unit test</p>
            </NavLink>
            <NavLink
              to="/practice-test"
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}
              className={({ isActive }) =>
                isActive ? ' text-white' : 'text-gray-500 hover:bg-gray-200'
              }
            >
              <QuizIcon style={{ fontSize: '40px' }} />
              <p className='text-[12px] '>Practice test</p>
            </NavLink>
            <NavLink
              to="/study"
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}
              className={({ isActive }) =>
                isActive ? ' text-white' : 'text-gray-500 hover:bg-gray-200'
              }
            >
              <LocalLibrary style={{ fontSize: '40px' }} />
              <p className='text-[12px] '>Study</p>
            </NavLink>
            <NavLink
              to="/profile"
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}
              className={({ isActive }) =>
                isActive ? ' text-white' : 'text-gray-500 hover:bg-gray-200'
              }
            >
              <Person style={{ fontSize: '40px' }} />
              <p className='text-[12px] '>Profile</p>
            </NavLink>
            {/* <NavLink
              to="/settings"
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}
              className={({ isActive }) =>
                isActive ? ' text-white' : 'text-gray-500 hover:bg-gray-200'
              }
            >
              <Settings style={{ fontSize: '40px' }} />
              <p className='text-[12px] '>Settings</p>
            </NavLink> */}
          </nav>
        );
      default:
        return null;
    }
  };

  return (
    <div className='h-screen bg-secondary font-playfair'>
      <Header user={user} />
      <div className="flex h-[93%]">
        {location.pathname === '/attend-test' || location.pathname === '/attend-practice' || location.pathname === '/plans' ? null : <aside className="w-24 bg-[#030350]  text-white ">
          {renderNavItems()}
        </aside>}

        <main className="flex-1  p-4 overflow-y-auto w-full">
          {children}
        </main>
      </div>
    </div>
  )
}

export default DashboardLayout