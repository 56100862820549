import React, { useEffect, useState } from 'react'
import { dashboardService } from '../../../service/dashboardService'
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

const Subscriptions = () => {
    const [list, setList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
  
    const fetchList = async () => {
      setIsLoading(true)
      try {
        const response = await dashboardService().getSubscriptions()
        setIsLoading(false)
        if (response.status === 200) {
          setList(response.data.results)
        } 
        console.log(response.data, 'response')
      } catch (error) {
        toast.error('Failed to retireve list')
      }
    }
  
  
    useEffect(() => {
      fetchList()
    }, [])
    
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">No:</th>
            <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Name</th>
            <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Plan</th>
            <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Validity</th>
            {/* <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-700">Status</th> */}
          </tr>
        </thead>
        <tbody>
          {list.map((item,index) => {
            const {student} =  item
            const {plan} = item
            return (
                <tr onClick={()=> navigate(`subscription-form/${item.id}`)} key={item.id} className="hover:bg-gray-100">
                  <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">{index + 1}</td>
                  <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">{student.first_name} {student.last_name}</td>
                  <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">{plan.description}</td>
                  <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">{dayjs(item.end_date).format("DD-MM-YYYY") }</td>
                  {/* <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">
                    {item.is_approved ? 'Approved':"Pending"}
                  </td> */}
                </tr>
              )
          }
        
        )}
        </tbody>
      </table>
    </div>
  );
};

export default Subscriptions