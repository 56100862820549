import React, { useEffect, useState } from 'react'
import PrimaryButton from '../../../components/button/PrimaryButton'
import { ChevronLeft, ChevronRight, Flag } from '@mui/icons-material'
import McqExerciseView from './McqExerciseView'
import SimulationTableExerciseView from './SimulationTableExerciseView'
import { useLocation, useNavigate } from 'react-router-dom'
import ResultModal from './ResultModal'
import { useDispatch } from 'react-redux'
import { showAnswer } from '../../../redux/exerciseTestSlice'
import { questionBankService } from '../../../service/questionBankService'
import { testService } from '../../../service/testService'
import LongAnswerExerciseView from './LongAnswerExerciseView'

function TestExerciseLayout() {
    const location = useLocation();
    const { testType, lectureId, gradeId, lecture } = location.state;
    const [questions, setQuestions] = useState([])
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)

    const [selectedOption, setSelectedOption] = useState(null)
    const [correctAnswer, setCorrectAnswer] = useState(null)
    const [shwCorrectAnswerNow, setShwCorrectAnswerNow] = useState(false);




    const navigate = useNavigate()

    const [showResultModal, setShowResultModal] = useState(false)
    const dispatch = useDispatch()

    console.log(testType, 'state data')

    const handleResultModalClose = () => {
        setShowResultModal(false)
        navigate('/study')
    }

    const handleFlag = () => {

    }


    const startTest = async () => {
        const response = await testService().startExerciseTest(testType, lectureId)
        if (response?.status === 200) {
            fetchQuestions()
        } else {
            console.log('error start')
        }
    }

    const fetchQuestions = async () => {
        let response
        if (testType === 'mcq') {
            response = await questionBankService().listMCQ(gradeId, lectureId)
        } else if(testType === 'simulation') {
            response = await questionBankService().listSimulations(gradeId, lectureId)

        }else{
            response = await questionBankService().listLongAnswers(gradeId, lectureId)
        }
        console.log(response, 'response list')
        if (response?.status === 200) {
            setQuestions(response?.data?.results)
        } else {
            console.log('error fetch')
        }
    }

    useEffect(() => {
        startTest()
    }, [lectureId])


  
    
    return (
        <div className=' relative'>
            <div className="flex w-full justify-between items-center  border-b-2 p-2">
                <p className="text-xl font-bold">{lecture} - {testType}</p>
                <PrimaryButton onClick={() => setShowResultModal(true)} type="button" className='text-white bg-highlight' >
                    Exit
                </PrimaryButton>
            </div>
            <div className='flex items-center justify-start p-4 gap-52'>
                <p className={`text-xl  text-amber-500 font-bold `}>{currentQuestionIndex + 1}/{questions?.length} </p>
                {testType === 'simulation' || testType === 'long_answer' ? <button onClick={() => dispatch(showAnswer(true))} className='p-2 bg-amber-500 rounded text-center text-sm text-white'>Show answer</button>:null}
            </div>

          
                    {testType === 'simulation' && <SimulationTableExerciseView setCorrectAnswer={setCorrectAnswer} answersObject={correctAnswer} setAnswersForSubmitting={setSelectedOption} answersForSubmitting={selectedOption} shwCorrectAnswerNow={shwCorrectAnswerNow} setShwCorrectAnswerNow={setShwCorrectAnswerNow} testType={testType} id={questions[currentQuestionIndex]?.id} />}
                    {testType === 'mcq' && <McqExerciseView testType={testType} setCorrectAnswer={setCorrectAnswer} correctAnswer={correctAnswer} setSelectedOption={setSelectedOption} selectedOption={selectedOption} id={questions[currentQuestionIndex]?.id} />}
                    {testType === 'long_answer' && <LongAnswerExerciseView testType={testType} setCorrectAnswer={setCorrectAnswer} correctAnswer={correctAnswer} setSelectedOption={setSelectedOption} selectedOption={selectedOption} id={questions[currentQuestionIndex]?.id} shwCorrectAnswerNow={shwCorrectAnswerNow} setShwCorrectAnswerNow={setShwCorrectAnswerNow}  />}

             
            <ResultModal type={testType} lectureId={lectureId} isOpen={showResultModal} handleClose={handleResultModalClose} />
            <div className='flex items-center justify-center gap-2'>
                <div onClick={() => {
                    //on previous record should be saved simulation
                    if (currentQuestionIndex != 0) {
                        setCorrectAnswer(null)
                        setSelectedOption(null)
                        setShwCorrectAnswerNow(false)
                        setCurrentQuestionIndex(prev => prev - 1)
                    }
                }} className='cursor-pointer border p-4 rounded bg-gray-100'>
                    <ChevronLeft />
                </div>
                <div
                    onClick={() => {
                    //on next record should be saved simulation

                        if (currentQuestionIndex + 1 != questions.length) {
                            setCorrectAnswer(null)
                            setSelectedOption(null)
                            setShwCorrectAnswerNow(false)
                            setCurrentQuestionIndex(prev => prev + 1)
                        }
                    }}
                    className='cursor-pointer  border p-4 rounded'>
                    <ChevronRight />
                </div>
            </div>
        </div>
    )
}

export default TestExerciseLayout