import React from 'react'

const SolutionTableView = ({header,celldata,answersObject}) => {
    return (
        <div className=' '>
            <table className='w-[90%] mx-auto bg-white'>
                <thead>
                    <tr>
                        <th></th>
                        {header.map(i => <th key={i} className='min-w-12  p-2 border border-gray-300'>{i?.toUpperCase()}</th>)}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {celldata?.map((row, rowIndex) => {
                        const rowAsArray = Object.entries(row)
                        return <tr key={rowIndex}>
                            <td className='border border-gray-400'>{rowIndex + 1}</td>
                            {rowAsArray.map((col, colIndex) => {
                                console.log(col,'col')
                                const cellItem = col[1]
                                return (
                                    <td  className={` relative text-[12px]`}>{cellItem.isQuestion ? answersObject[cellItem.cell]: cellItem?.value}</td>
                                )
                            })}
                        </tr>
                    })}
                </tbody>
            </table>

        </div>
    )
}

export default SolutionTableView