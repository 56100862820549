import React, { useEffect, useState } from 'react';
import './modal.css'; // Import the CSS file for styling
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import OptionsCreate from '../optionsForm/OptionsCreate';
import { cellInputschema } from '../../utils/schema';
import { Modal } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const ModalInput = ({ isOpen, onClose, onSave, cellData }) => {
    console.log(cellData, 'cellData')
    const [inputValue, setInputValue] = useState('');
    const { control, handleSubmit, formState: { errors }, setValue, register, reset, watch } = useForm({
        resolver: yupResolver(cellInputschema),
        defaultValues: {
            cellValue: cellData.value,
            questionType: cellData.questionType,
            isQuestion: cellData.isQuestion,
            options: cellData.options,
            correctOption: cellData?.correctOption ? cellData?.correctOption : null,
        },
    });

    const isQuestion = watch('isQuestion')

    const onSubmit = (data) => {
        onSave(data);
        reset()
    };

    const handleClose = () => {
        reset()
        onClose()
    };

    useEffect(() => { console.log(errors, 'errors') }, [errors])

    const renderQuestionType = () => {
        console.log(cellData.questionType,'cellData.questionType')
        if (isQuestion && cellData.questionType === 'text') {
            return <OptionsCreate errors={errors} control={control} />
        } else {
           return <input
                type={cellData.questionType}
                className="modal-input mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"

                {...register('cellValue')}
            />
        }
    }

    if (!isOpen) return null;

    return (
        <Modal open={isOpen} onClose={handleClose}>
            <form onSubmit={handleSubmit(onSubmit)} style={style} className="modal-content w-full relative !pt-[40px] ">
                <h2 className='bg-highlight absolute top-0 left-0 w-full rounded-t-lg text-white capitalize font-bold   '>{cellData?.cell}</h2>
                {renderQuestionType()}

                <div onClick={() => setValue('isQuestion', !isQuestion)} className='flex items-center gap-x-2 cursor-pointer mb-4'>
                    <p className='text-sm'>Mark as question</p>
                    {isQuestion ? <CheckBoxIcon /> :
                        <CheckBoxOutlineBlankIcon />}
                </div>
                <div className="modal-buttons">
                    <button type="submit" className="modal-save-button p-2 bg-gray-500 rounded text-white">Save</button>
                    <button onClick={handleClose} className="modal-close-button">Close</button>
                </div>
            </form>
        </Modal>
    );
};

export default ModalInput;
