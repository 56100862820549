import React, { useEffect, useState } from 'react'
import { questionBankService } from '../../../service/questionBankService'
import { CalendarViewMonthOutlined, List, Refresh } from '@mui/icons-material'
import DOMPurify from 'dompurify';
import { useSelector } from 'react-redux';
import { testService } from '../../../service/testService';
import Loader from '../../../components/loader/Loader';
import ModalInputSimulation from '../testExercise/ModalInputSimulation';
import { practiceTestService } from '../../../service/practiceTestService';
import PrimaryButton from '../../../components/button/PrimaryButton';
import { unitTestService } from '../../../service/unitTestService';


const SimulationUnitTestView = ({ id, testType, setCorrectAnswer, answersObject, setAnswersForSubmitting, answersForSubmitting, setShwCorrectAnswerNow, shwCorrectAnswerNow }) => {
  console.log(id, 'id simulation')
  const [data, setData] = useState(null)
  const [celldata, setCelldata] = useState(null)
  const [loading, setLoading] = useState(false)
  const [header, setHeader] = useState([])
  const [selectedCell, setSelectedCell] = useState(null)
  const [isModalOpen, setisModalOpen] = useState(false);
  const [checkingAnswer, setCheckingAnswer] = useState(false)
  const isShowAnswer = useSelector((state) => state.exercise.showAnswer);



  const handleCellClick = (cell) => {
    console.log('selected cell')
    setSelectedCell(cell);
  };

  const handleCloseModal = () => {
    setSelectedCell(null);
    setisModalOpen(false);
  };

  const handleSaveModal = (data) => {
    let rowsArr = celldata.map(row => ({ ...row, [selectedCell.columnType]: { ...row[selectedCell.columnType] } }));
    let rowObj = rowsArr[selectedCell.rowIndex];
    let answerSubmitData = { ...answersForSubmitting }
    // let columnObj = rowObj[selectedCell.columnType] ;
    if (data?.questionType === 'text') {
      rowObj[selectedCell.columnType].value = data?.options[data.correctOption].value;
    } else {
      rowObj[selectedCell.columnType].value = data?.cellValue;
    }
    if (data?.isQuestion) {
      if (data?.questionType === 'text') {
        answerSubmitData[selectedCell.cell] = data?.options[data.correctOption].value
      } else {
        answerSubmitData[selectedCell.cell] = data?.cellValue
      }
    }
    setCelldata(rowsArr);
    setAnswersForSubmitting(answerSubmitData)
    setisModalOpen(false);
    setSelectedCell(null);
  };


  const submitAnswer = async () => {
    setCheckingAnswer(true)

    let submitData = {
      answers: answersForSubmitting
    }
    try {
      const response = await unitTestService().submitAnswer(id, submitData)
      setCheckingAnswer(false)
      console.log(response, 'res')
      // if (response?.status === 200) {

      // }
      console.log('res submi  answer', response)
    } catch (error) {
      console.log('error submit answer', error.message)
    }
  }



  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await questionBankService().getSimulationDetails(id)
      setLoading(false)
      console.log(response, 'res')
      if (response?.status === 200) {
        setData(response?.data)
        setCelldata(response?.data?.cellData)
      }
      console.log('res', response)
    } catch (error) {
      console.log('error', error.message)
    }
  }

  const resetTable = async () => {
    const response = await testService().resetSimulation(id)
    if (response.status === 200) {
      fetchData()
    } else {
      alert('Failed to reset!')
    }
    console.log(response, 'res')
  }

  useEffect(() => {
    if (id) fetchData()
  }, [id])

  useEffect(() => {
    if (celldata?.length > 0) {
      const firstObject = celldata[0]
      setHeader(Object.values(firstObject).map(item => item.columnName))
    }

    // if (ddata.length > 0)setHeader()
  }, [celldata])

  useEffect(() => {
    if (selectedCell) {
      console.log('modal trigger')
      setisModalOpen(true);
    }
  }, [selectedCell]);


  const getCellStyle = (cellItem) => {
    if (cellItem?.isQuestion) {
      return { borderWidth: '2px', borderColor: 'orange ' }
    } else {
      return { borderWidth: '1px', borderColor: 'gray ' }
    }
  }


  return (
    <div className="flex bg-gray-200">
      <div className="w-2/3 bg-white border rounded p-2 h-[65vh] overflow-auto relative" >

        <div className='h-[85%] relative'>
          {/* <div onClick={() => resetTable()} className='top-0 cursor-pointer'> <Refresh /> </div> */}

          {loading ? <div>Loading....</div> : <div className="p-6  bg-white">
            {/* <h2 className="text-xl font-semibold mb-8">{data?.question}</h2> */}
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data?.question) }} />
            <div style={{ borderColor: 'wheat' }} className="grid grid-cols-2 gap-4 mt-8">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    {header.map(i => <th key={i} className='min-w-52  p-2 border border-gray-300'>{i?.toUpperCase()}</th>)}

                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {celldata?.map((row, rowIndex) => {
                    const rowAsArray = Object.entries(row)
                    // console.log(rowAsArray[1], 'row as array')
                    return <tr key={rowIndex}>
                      <td className='border border-gray-300'>{rowIndex + 1}</td>
                      {rowAsArray.map((col, colIndex) => {
                        // console.log(col[1], 'col', rowIndex)
                        const cellItem = col[1]
                        return (
                          <td onClick={() => {
                            if (cellItem?.isQuestion) {
                              handleCellClick(cellItem)
                            }
                          }} className={` relative`} style={getCellStyle(cellItem)} >{cellItem?.value}
                            {cellItem?.isQuestion && <div className="w-4 h-4 rounded bg-gray-400 absolute top-2 right-2 grid place-content-center">
                              {cellItem.isQuestion && cellItem.questionType === 'date' ? <CalendarViewMonthOutlined style={{ color: '#fff', fontSize: '12px' }} /> : null}
                              {cellItem.isQuestion && cellItem.questionType === 'text' ? <List style={{ color: '#fff', fontSize: '12px' }} /> : null}
                              {cellItem.isQuestion && cellItem.questionType === 'number' ? <p className='text-white font-bold text-[8px]'>123</p> : null}
                            </div>}
                          </td>
                        )
                      })}
                    </tr>
                  })}
                </tbody>
              </table>
            </div>
            <PrimaryButton className='mt-16' onClick={() => submitAnswer()}>Submit</PrimaryButton>

          </div>}
          {selectedCell ? (
            <ModalInputSimulation
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              onSave={handleSaveModal}
              cellData={selectedCell}
            />
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default SimulationUnitTestView