import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { testService } from '../../../service/testService'
import Loader from '../../../components/loader/Loader'
import { Flag } from '@mui/icons-material'
import { questionBankService } from '../../../service/questionBankService'
import { practiceTestService } from '../../../service/practiceTestService'

const optionLabels = ['A', 'B', 'C', 'D']

const McqPracticeTestView = ({ id, setSelectedOption, selectedOption, testType }) => {
  console.log(id,'id in')
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [checkingAnswer, setCheckingAnswer] = useState(false)


  console.log(data, 'data')

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await questionBankService().getMcqDetails(id)
      setLoading(false)
      console.log(response, 'res')
      if (response?.status === 200) {
        setData(response?.data)
      }
      console.log('res', response)
    } catch (error) {
      console.log('error', error.message)
    }
  }



  const submitAnswer = async () => {
    setCheckingAnswer(true)

    let submitData = {
      answer: selectedOption
    }
    try {
      const response = await practiceTestService().submitAnswer(id, submitData)
      setCheckingAnswer(false)
      console.log(response, 'res')
      // if (response?.status === 200) {

      // }
      console.log('res submi  answer', response)
    } catch (error) {
      console.log('error submit answer', error.message)
    }
  }

  useEffect(() => {
    if (id) fetchData()
  }, [id])


  const getColorForOption = (option) => {
    console.log(option, 'option')

    if (selectedOption === option) {
        return { backgroundColor: 'orange' }
    } else {
      return { backgroundColor: 'white' }
    }
  }

  useEffect(() => {
    if (data?.last_answered) {
      setSelectedOption(data?.last_answered)
    }
  }, [data])

  useEffect(()=>{
if(selectedOption){
  if(selectedOption != data?.last_answered){
    submitAnswer()
  }
}
  },[selectedOption])

  return (
    <div className="flex bg-gray-200">
      <div className="w-2/3 bg-white border rounded p-2 h-[65vh] overflow-auto relative" >
        <div className='h-[85%] relative'>
          {checkingAnswer && <div className='absolute top-0 left-0 bg-white opacity-[0.8] h-full w-full grid place-content-center'><Loader /> </div>}
          {loading ? <div className='h-full w-full grid place-content-center'>Loading....</div> : <div className="p-6  bg-white">
            <h2 className="text-xl font-semibold mb-8">{data?.question}</h2>
            {data && data.id ? <div className="grid grid-cols-2 gap-4">
              {optionLabels.map(option => (
                <div key={option} onClick={() => setSelectedOption(option)} style={getColorForOption(option)} className={`p-4 rounded-lg shadow-md  cursor-pointer`}>
                  <label className="flex items-center space-x-3">
                    <span className="text-gray-700">{data[`option_${option.toLowerCase()}`]}</span>
                  </label>
                </div>
              ))}
            </div> : null}
          </div>}
        </div>
      
      </div>
      
    </div>
  )
}

export default McqPracticeTestView