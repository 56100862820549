import { apiAuthenticatedRequest, apiRequest } from '../api/apiRequest'

export const dashboardService = () => {
  return {
    getStudentsList: (headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'GET',
        url: `/students`,
        headers
      })
    },
    getStudentDetail: (id,headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'GET',
        url: `/students/${id}`,
        headers
      })
    },
    getStudentsPendingList: (headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'GET',
        url: `/students/pending-approval/`,
        headers
      })
    },
    approveStudent: (id, headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'PATCH',
        url: `/students/${id}/approve/`,
        headers
      })
    },
    getProfile: (headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'GET',
        url: `students/me`,
        headers
      })
    },
    getStaffsList: (headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'GET',
        url: `/staffs/`,
        headers
      })
    },
    addStaff: (data, headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'POST',
        url: '/staffs/',
        data,
        headers
      })
    },
    deleteStaff: (id, headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'DELETE',
        url: `/staffs/${id}`,
        headers
      })
    },
    getLectures: (headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'GET',
        url: `/lectures/`,
        headers
      })
    },
    getLecturesBasedOnGrade: (gradeId,headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'GET',
        url: `lectures/?grade=${gradeId}`,
        headers
      })
    },
   
    addLecture: (data, headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'POST',
        url: `/lectures/`,
        data,
        headers
      })
    },
    updateLecture: (id,data, headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'PATCH',
        url: `/lectures/${id}/`,
        data,
        headers
      })
    },
    
    getLectureDetails: (id, headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'GET',
        url: `/lectures/${id}`,
        headers
      })
    },
    getAnalyticData: ( headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'GET',
        url: `/students/progress`,
        headers
      })
    },
    getAnalyticDataByLectures: ( headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'GET',
        url: `/students/progress/by-lectures/`,
        headers
      })
    },
    getSubscriptions: (headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'GET',
        url: `/subscriptions/`,
        headers
      })
    },
    getStudentSubscriptions: (id,headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'GET',
        url: `/subscriptions/?student_id=${id}`,
        headers
      })
    },
    getSubscriptionPlans: (headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'GET',
        url: `/subscriptions/plans`,
        headers
      })
    },
    getActiveSubscriptionDetail: (headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'GET',
        url: `/subscriptions/active/`,
        headers
      })
    },
    addSubscription: (data, headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'POST',
        url: `/subscriptions/`,
        data,
        headers
      })
    },
   
    
  }
}
