import React from 'react'
import { Controller } from 'react-hook-form'

const OptionsCreate = ({control,errors}) => {

  return (
    <div className="mb-4">
    {Array.from({ length: 4 }).map((_, index) => (
      <div key={index} className="mb-2">
        <label className="block text-[10px] rounded-t-md font-medium text-gray-700  w-1/3 text-left bg-gray-500  text-white p-2">Option {index + 1}</label>
        <div className="flex items-center">
          <Controller
            name={`options.${index}.value`}
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                className={`w-full p-2 border ${errors.options?.[index]?.value ? 'border-red-500' : 'border-gray-300'} rounded`}
              />
            )}
          />
          <Controller
            name="correctOption"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="radio"
                value={index}
                checked={field.value == index}
                onChange={() => field.onChange(index)}
                className="ml-2"
              />
            )}
          />
        </div>
        {errors.options?.[index]?.value && <p className="text-red-500 text-sm mt-1">{errors.options[index].value.message}</p>}
      </div>
    ))}
    {errors.correctOption && <p className="text-red-500 text-sm mt-1">{errors.correctOption.message}</p>}
  </div>
  )
}

export default OptionsCreate