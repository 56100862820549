

import { apiAuthenticatedRequest, apiRequest } from '../api/apiRequest'

export const unitTestService = () => {
  return {
   
    startUnitTest: (data, headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'POST',
        url: `exams/unit/start/`,
        headers,
        data,
      })
    },
    getUnitTestQuestions: (headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'GET',
        url: `exams/unit/questions/v2/`,
        headers
      })
    },
    submitAnswer: (id,data,testType, headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'POST',
        url: `/exams/exercise/${testType}/${id}/submit/`,
        data,
        headers
      })
    },
    getUnitTestSummary: (headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'GET',
        url: `/exams/unit/summary/`,
        headers
      })
    },
    submitAnswer: (id,data, headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'POST',
        url: `/exams/unit/${id}/submit/`,
        data,
        headers
      })
    },
    getUnitTestSolutionById: (id,headers = {}) => {
      return apiAuthenticatedRequest({
        method: 'GET',
        url: `/exams/unit/ques/${id}/solution/`,
        headers
      })
    },
 
    
    
  }
}
