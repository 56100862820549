import React, { useEffect, useState } from 'react'
import { CheckBoxOutlineBlank, ChevronLeft, ChevronRight, Delete } from '@mui/icons-material'
import PrimaryButton from '../../../components/button/PrimaryButton'
import CheckBox from '@mui/icons-material/CheckBox'
import { useNavigate } from 'react-router-dom'
import { dashboardService } from '../../../service/dashboardService'
import { toast } from 'react-toastify'
import WarningModal from '../../../components/modal/WarningModal'

function UnitTest() {
    const [selected, setSelected] = useState([])
    const [modules, setModules] = useState([])
    const [showWarning,setShowWarning] = useState(false)

    const navigate = useNavigate()

    const handleSelection = (item) => {
        if (selected.includes(item.id)) {
            setSelected(prev => prev.filter(i => i != item.id))
        } else {
            setSelected(prev => [...prev, item.id])
        }
    }

    const fetchModules = async () => {
        try {
            const response = await dashboardService().getLectures()
            if (response?.status === 200) {
                setModules(response?.data?.results)
            } else {
                toast.error('Something went wrong')
            }
            console.log(response, 'response')
        } catch (error) {
            console.log(error, 'error')
        }
    }

    useEffect(() => {
        fetchModules()
    }, [])

    return (
        <div className=''>
            <div className="container mx-auto p-4 ">
                <label className="mb-2 block text-sm font-medium text-gray-700" htmlFor="select_module">Select modules out of which questions should be generated</label>
                <div className="border w-1/2 p-4 rounded bg-white">
                    {modules.filter(i=> i.is_unlocked).map(i => <div onClick={() => handleSelection(i)} className="flex cursor-pointer items-center gap-2">
                        {selected.includes(i.id) ? <CheckBox /> : <CheckBoxOutlineBlank />}
                        <p className="text-sm">{i.title} </p>
                    </div>)}
                </div>
            </div>

            <PrimaryButton  onClick={() => setShowWarning(true) }>Start</PrimaryButton>
              <WarningModal isOpen={showWarning} onClose={() => setShowWarning(false)} onProceed={() => {
               navigate("/attend-unit", {
                state: {
                    lecture_ids: selected,
                }
            })}} />
        </div>
    )
}

export default UnitTest