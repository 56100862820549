import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { loginSchema } from '../../../utils/schema';
import { authService } from '../../../service/authService';
import { toast } from 'react-toastify';
import { loginSuccess } from '../../../redux/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
import PrimaryButton from '../../../components/button/PrimaryButton';
import { Link, useNavigate } from 'react-router-dom';

// Define the validation schema using Yup


const Login = () => {
  // Set up the form with useForm and yupResolver
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onSubmit = async data => {
    setIsLoading(true);

    console.log(data);
    const formData = {
      email: data?.email,
      password: data?.password,
    }
    try {
      const response = await authService().login(formData)
      setIsLoading(false)
      if (response?.status === 200) {
        const token = response?.data?.access;
        const refresh = response?.data?.refresh;
        if (token) {
          // Decode the token
          const decoded = jwtDecode(token);
          console.log(decoded, 'decoded')
          dispatch(loginSuccess({ token, user: decoded ,refresh}));
          navigate('/dashboard')
        } else {
          toast.error('No token issued')
        }
      } else {
        const responseObj = response?.response?.data
        const firstKey = Object.keys(responseObj)[0];
        const message = responseObj[firstKey][0]
        console.log(message, 'msg')
        toast.error(message)
      }

    } catch (error) {
      console.log(error.message, 'error')
      toast.error('Something went wrong try again later')
    }

  };
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);


  useEffect(()=>{
    if(isAuthenticated) navigate('/dashboard')
  },[])

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-8 bg-white rounded shadow-md">
        <h2 className="text-2xl font-bold text-center">Login</h2>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <input
              id="email"
              type="email"
              {...register('email')}
              className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            {errors.email && <p className="mt-2 text-sm text-red-600">{errors.email.message}</p>}
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
            <input
              id="password"
              type="password"
              {...register('password')}
              className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            {errors.password && <p className="mt-2 text-sm text-red-600">{errors.password.message}</p>}
          </div>

          <PrimaryButton
            className="block w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            type="submit" isLoading={isLoading} >
            Login
          </PrimaryButton>
        </form>
      </div>
    </div>
  );
};

export default Login;
