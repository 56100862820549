import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { publicService } from '../../../service/publicService'
import TooltipError from '../../../components/tooltipError/TooltipError'

const GenderSelectInput = ({ control, errors }) => {

    return (
        <div>
            <label className="block text-gray-700 text-[12px]">Gender</label>
            <Controller
                name="gender"
                control={control}
                render={({ field }) => (
                    <select {...field} className={`mt-1 p-2 w-full border rounded  text-[12px] ${errors.gender ? 'border-red-500' : 'border-gray-300'}`}>
                          <option  value={''}>Select Gender
                          </option>
                            <option  value={'male'}>Male
                            </option>
                            <option  value={'female'}>Female
                            </option>
                    </select>
                )}
            />
              {errors.gender && <TooltipError message={errors.gender.message} />}
        </div>
    )
}

export default GenderSelectInput