// src/redux/authSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {question_type:'',lecture_ids:[],questions_count:0}
export const practiceTestSlice = createSlice({
  name: 'practice',
  initialState:initialState,
  reducers: {
    setTestParams: (state,action) => {
      state.question_type = action.payload.question_type;
      state.lecture_ids = action.payload.lecture_ids;
      state.questions_count = action.payload.questions_count;
    },
    clearTestParams: (state) => {
      state = initialState;
    },
  
  },
});

export const { setTestParams,clearTestParams } = practiceTestSlice.actions;

export default practiceTestSlice.reducer;
